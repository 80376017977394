const USER_API = process.env.REACT_APP_USER_API;
const FULFILMENT_API = process.env.REACT_APP_FULFILMENT_API;

const CommonEndpoints = {
  USER: {
    POST: {
      LOGIN: USER_API + "/api/user/wmslogin",
      ADD_COMPANY_USER: USER_API + "/api/user/createforcompany",
    },
    GET: {
      GET_FILTERED_USERS: USER_API + "/api/user/by",
      GET_USER_WITH_ROLES: USER_API + "/api/user/getuserwithroles/{id}",
      GET_ROLE_OPTIONS_FOR_COMPANY:
        USER_API + "/api/role/getroleoptionsforcompany",
      GET_FEATURE_BREAKDOWN_FOR_USER: USER_API + "/api/feature/byuser/{id}",
      GET_USER: USER_API + "/api/user/getbyuserid/{id}",
    },
    PUT: {
      TOGGLE_USER: USER_API + "/api/user/toggleuser/{id}",
      EDIT_USER_DETAILS: USER_API + "/api/user/editdetails",
      UPDATE_OR_CREATE_FEATUREUSER:
        USER_API +
        "/api/featureuser/updateorcreatefeatureuser/{featureID}/{userID}/{access}",
      EDIT_PASSWORD_USER: USER_API + "/api/user/editpassword",
    },
    DELETE: {
      DELETE_USER: USER_API + "/api/user/{id}",
    },
  },
  PASSWORD: {
    GET: {
      BY_ID: USER_API + "/api/passwordresetrequest/getliverequestbyid/",
    },
    PUT: {
      CHANGE_PASSWORD: USER_API + "/api/passwordresetrequest/changepassword",
      RESET_PASSWORD: USER_API + "/api/passwordresetrequest/resetpassword/",
    },
  },
  WAREHOUSE: {
    GET: {
      COUNTS: FULFILMENT_API + "/api/warehousemanagement/run/counts",
      COURIERS: FULFILMENT_API + "/api/warehousemanagement/couriers",
      ALL: USER_API + "/api/warehouse/all",
    },
  },
  RUNS: {
    GET: {
      ORDER_PICK: FULFILMENT_API + "/api/warehousemanagement/run/pick/all",
      ORDER_PACK: FULFILMENT_API + "/api/warehousemanagement/run/pack/all",
      BULK_SHELF:
        FULFILMENT_API + "/api/warehousemanagement/run/bulkshelfpick/all",
      BULK_PALLET:
        FULFILMENT_API + "/api/warehousemanagement/run/bulkpalletpick/all",
      BULK_PACK: FULFILMENT_API + "/api/warehousemanagement/run/bulkpack/all",
      REPLEN_STOCK:
        FULFILMENT_API + "/api/warehousemanagement/run/stockreplenishment/all",
      REPLEN_PFP:
        FULFILMENT_API + "/api/warehousemanagement/run/pfpreplenishment/all",

      ORDER_PICK_DETAILS:
        FULFILMENT_API + "/api/warehousemanagement/run/pick/{id}/details",
      ORDER_PACK_DETAILS:
        FULFILMENT_API + "/api/warehousemanagement/run/pack/{id}/details",
      BULK_SHELF_DETAILS:
        FULFILMENT_API +
        "/api/warehousemanagement/run/bulkshelfpick/{id}/details",
      BULK_PALLET_DETAILS:
        FULFILMENT_API +
        "/api/warehousemanagement/run/bulkpalletpick/{id}/details",
      BULK_PACK_DETAILS:
        FULFILMENT_API + "/api/warehousemanagement/run/bulkpack/{id}/details",
      REPLEN_STOCK_DETAILS:
        FULFILMENT_API +
        "/api/warehousemanagement/run/stockreplenishment/{id}/details",
      REPLEN_PFP_DETAILS:
        FULFILMENT_API +
        "/api/warehousemanagement/run/pfpreplenishment/{id}/details",
    },
    PUT: {
      ORDER_PICK_BUMP:
        FULFILMENT_API + "/api/warehousemanagement/run/pick/{id}/bump",
      ORDER_PACK_BUMP:
        FULFILMENT_API + "/api/warehousemanagement/run/pack/{id}/bump",
      BULK_SHELF_BUMP:
        FULFILMENT_API + "/api/warehousemanagement/run/bulkshelfpick/{id}/bump",
      BULK_PALLET_BUMP:
        FULFILMENT_API +
        "/api/warehousemanagement/run/bulkpalletpick/{id}/bump",
      BULK_PACK_BUMP:
        FULFILMENT_API + "/api/warehousemanagement/run/bulkpack/{id}/bump",
      REPLEN_STOCK_BUMP:
        FULFILMENT_API +
        "/api/warehousemanagement/run/stockreplenishment/{id}/bump",
      REPLEN_PFP_BUMP:
        FULFILMENT_API +
        "/api/warehousemanagement/run/pfpreplenishment/{id}/bump",
    },
  },
  FULFILMENT: {
    GET: {
      LABELS: FULFILMENT_API + "/api/labelprintqueue/getqueue",
      INBOUND_STOCK_LABELS: FULFILMENT_API + "/api/checkinrun/labels/{warehouseid}",
      MANIFESTS: FULFILMENT_API + "/api/manifest/getmanifests",
      MANIFEST: FULFILMENT_API + "/api/manifest/download/",
      RETURNS: FULFILMENT_API + "/api/return/checkin/labels",
      RETURN_LABEL: FULFILMENT_API + "/api/return/checkin/label/{id}",
      RETURN_ISSUES: FULFILMENT_API + "/api/return/issues",
      RESTOCK_BIN_ITEM_BOX_LABELS: FULFILMENT_API + "/api/return/restockbinitemboxlabels",
      DOWNLOAD_RESTOCK_BIN_ITEM_BOX_LABEL: FULFILMENT_API + "/api/return/restockbinitemboxlabel/{id}",
    },
    PUT: {
      MANIFEST: FULFILMENT_API + "/api/manifest/markasprinted/",
      MARK_AS_PRINT_RETURN: FULFILMENT_API + "/api/return/checkin/item/label/{id}",
      CONFIRM_RETURN_ISSUE: FULFILMENT_API + "/api/return/issue/{id}/{confirmed}",
      PRINT_RESTOCK_BIN_ITEM_BOX_LABEL: FULFILMENT_API + "/api/return/restockbinitemboxlabel/{id}/print"
    },
  },
  FULFILMENT_CONFIGURATION: {
    GET: {
      SEARCH: FULFILMENT_API + "/api/FulfilmentConfiguration/Search",
    },
  },
  WMS_ADMIN: {
    USER: {
      DELETE: {
        DELETE_USER: USER_API + "/api/user/warehouse/{warehouseid}/user/{id}",
      },
      GET: {
        GET_FILTERED_USERS: USER_API + "/api/user/warehouse/{warehouseid}/by",
        GET_USER_WITH_ROLES:
          USER_API + "/api/user/warehouse/{warehouseid}/getuserwithroles/{id}",
        GET_FEATURE_BREAKDOWN_FOR_USER:
          USER_API + "/api/feature/warehouse/{warehouseid}/byuser/{id}",
        GET_ROLE_OPTIONS_FOR_COMPANY:
          USER_API +
          "/api/role/warehouse/{warehouseid}/getroleoptionsforcompany",
        GET_USER:
          USER_API + "/api/user/warehouse/{warehouseid}/getbyuserid/{id}",
      },
      PUT: {
        TOGGLE_USER:
          USER_API + "/api/user/warehouse/{warehouseid}/toggleuser/{id}",
        EDIT_USER_DETAILS:
          USER_API + "/api/user/warehouse/{warehouseid}/editdetails",
        UPDATE_OR_CREATE_FEATUREUSER:
          USER_API +
          "/api/featureuser/warehouse/{warehouseID}/updateorcreatefeatureuser/{featureID}/{userID}/{access}",
        EDIT_PASSWORD_USER:
          USER_API + "/api/user/warehouse/{warehouseid}/editpassword",
      },
      POST: {
        ADD_COMPANY_USER:
          USER_API + "/api/user/warehouse/{warehouseid}/createforcompany",
      },
    },
    WAREHOUSE: {
      GET: {
        PENDING_COUNTS:
          FULFILMENT_API + "/api/warehousemanagement/run/pendingcounts",
        IN_PROGRESS_COUNTS:
          FULFILMENT_API + "/api/warehousemanagement/run/inprogresscounts",
      },
    },
    RUNS: {
      GET: {
        ORDER_PICK:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/pick/all",
        ORDER_PACK:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/pack/all",
        BULK_SHELF:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/bulkshelfpick/all",
        BULK_PALLET:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/bulkpalletpick/all",
        BULK_PACK:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/bulkpack/all",
        REPLEN_STOCK:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/stockreplenishment/all",
        REPLEN_PFP:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/pfpreplenishment/all",

        ORDER_PICK_DETAILS:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/pick/{id}/details",
        ORDER_PACK_DETAILS:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/pack/{id}/details",
        BULK_SHELF_DETAILS:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/bulkshelfpick/{id}/details",
        BULK_PALLET_DETAILS:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/bulkpalletpick/{id}/details",
        BULK_PACK_DETAILS:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/bulkpack/{id}/details",
        REPLEN_STOCK_DETAILS:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/stockreplenishment/{id}/details",
        REPLEN_PFP_DETAILS:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/pfpreplenishment/{id}/details",
      },
      PUT: {
        ORDER_PICK_BUMP:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/pick/{id}/bump",
        ORDER_PACK_BUMP:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/pack/{id}/bump",
        BULK_SHELF_BUMP:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/bulkshelfpick/{id}/bump",
        BULK_PALLET_BUMP:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/bulkpalletpick/{id}/bump",
        BULK_PACK_BUMP:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/bulkpack/{id}/bump",
        REPLEN_STOCK_BUMP:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/stockreplenishment/{id}/bump",
        REPLEN_PFP_BUMP:
          FULFILMENT_API +
          "/api/warehousemanagement/warehouse/{warehouseid}/run/pfpreplenishment/{id}/bump",
      },
    },
    PROBLEMS: {
      GET: {
        PROBLEM_COUNTS:
          FULFILMENT_API +
          "/api/warehousemanagement/problems/GetTotalProblemCounts",
        RETURN_ISSUES_COUNT:
          FULFILMENT_API +
          "/api/warehousemanagement/problems/returns/count",
        PROBLEM_PALLET_PARCELS:
          FULFILMENT_API +
          "/api/warehousemanagement/problems/GetProblemPalletParcels",
        PROBLEM_PALLET_PARCEL:
          FULFILMENT_API +
          "/api/warehousemanagement/problems/GetProblemPalletParcelByID/{id}",
        PROBLEM_LOCATIONS:
          FULFILMENT_API +
          "/api/warehousemanagement/problems/GetProblemLocations",
        PROBLEM_LOCATION:
          FULFILMENT_API +
          "/api/warehousemanagement/problems/GetProblemLocationByID/{id}",
      },
      PUT: {
        RESOLVE_PROBLEM_LOCATION:
          FULFILMENT_API + "/api/problemlocation/ResolveProblemLocation/{id}",
        RESOLVE_PROBLEM_PARCEL:
          FULFILMENT_API + "/api/problempalletparcel/ResolveProblemParcel/{id}"
      },
    },
  },
};

export default CommonEndpoints;
