import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FormValidator from '../common/FormValidator';
import { Row, Col, Form, FormControl, FormLabel, FormGroup, Button } from 'react-bootstrap';
import { GET, POST, extractData } from '../../Consumer';
import Endpoints from '../common/Endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import LoadingBar from '../common/LoadingBar';
import { ErrorAlert } from '../common/Alerts';
import { UsersSubNav, StrongPasswordPattern, NumberPattern, SpecialCharPattern, LengthPattern } from '../common/Constants'
import Tab from '../common/navigation/Tab';
import { getUser } from '../../Utilities';

const ROLES_ERROR_MESSAGE = 'Could not retrieve roles';
const SUBMIT_ERROR_MESSAGE = 'Could not add user';
const ROLE_VALIDATION_MESSAGE = 'You need to select at least one user preference to continue';
const VALIDATION_ERROR_MESSAGE = 'There seems to be a problem with some of the details entered, try again.';

class AddUser extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'forename',
                method: 'isEmpty',
                validWhen: false,
                message: `Enter the warehouse user's first name`
            },
            {
                field: 'surname',
                method: 'isEmpty',
                validWhen: false,
                message: `Enter the warehouse user's last name`
            },
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: `Enter the warehouse user's email`
            },
            {
                field: 'password',
                method: 'matches',
                args: [StrongPasswordPattern],
                validWhen: true,
                message: 'Password does not meet the criteria, try again'
            },
            {
                field: 'password',
                method: 'isLength',
                args: [{min: 6, max: 50}],
                validWhen: true,
                message: 'Password must be between 6 and 50 characters long'
            }
        ]);

        this.state = {
            loading: true,
            validation: this.validator.valid(),
            errorMessage: '',
            roleOptions: {},
            passwordNumberCheck: false,
            passwordSpecialCharCheck: false,
            passwordLengthCheck: false,
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            user: {
                id: '',
                forename: '',
                surname: '',
                email: '',
                password: '',
                roles: []
            },
            validationMessages: {
                roles: false,
                passwords: false
            },
            submitted: false
        };
    }

    componentDidMount = () => this.fetchRoleOptions();

    validate = (user) => {
        let validation = this.validator.validate(user);

        if (!user.password) {
            validation.isValid = false;
            validation.password.isInvalid = true;
        }
        return validation;
    }

    fetchRoleOptions = () => {
        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== '' ? Endpoints.WMS_ADMIN.USER.GET.GET_ROLE_OPTIONS_FOR_COMPANY.replace("{warehouseid}", currentlySelectedWarehouse) : Endpoints.USER.GET.GET_ROLE_OPTIONS_FOR_COMPANY);

        return GET(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ errorMessage: ROLES_ERROR_MESSAGE, loading: false });
                else this.setState({ roleOptions: data, loading: false });
            }).catch(() => this.setState({ errorMessage: ROLES_ERROR_MESSAGE, loading: false }));
    }

    handleInputChange = (e) => {
        const { name, value, type } = e.target;

        if (name === "password") {
            var numberPass = NumberPattern.test(value);
            var specialCharPass = SpecialCharPattern.test(value);
            var lengthPass = LengthPattern.test(value);

            this.setState(prevState => ({
                passwordNumberCheck: numberPass,
                passwordSpecialCharCheck: specialCharPass,
                passwordLengthCheck: lengthPass,
                user: {
                    ...prevState.user,
                },
                validationMessages: {
                    ...prevState.validationMessage
                }
            }));
        }

        if (type === 'checkbox') {
            let currRole = this.state.user.roles.indexOf(name);
            if (currRole > -1) {
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: [...prevState.user.roles.slice(0, currRole), ...prevState.user.roles.slice(currRole + 1)] }
                    }
                }));
            }
            else {
                let newRoles = this.state.user.roles;
                newRoles.push(name);
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: newRoles }
                    }
                }));
            }
        }
        else {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    ...{ [name]: value }
                }
            }));
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();

        const { user } = this.state;
        const { currentlySelectedWarehouse } = this.props;

        const userFromStorage = getUser();
        const warehouseID = currentlySelectedWarehouse === null || currentlySelectedWarehouse === undefined || currentlySelectedWarehouse === '' 
        ? userFromStorage.warehouseIDs[0] : currentlySelectedWarehouse;
     
        const url = new URL(Endpoints.WMS_ADMIN.USER.POST.ADD_COMPANY_USER.replace("{warehouseid}", warehouseID));

        if (!user.password) user.password = '';

        const validation = this.validator.validate(user);
        this.setState({ validation: validation, loading: validation.isValid, submitted: true });

        if (user.roles.length === 0) {
            validation.isValid = false;
            this.setState({ validationMessages: { roles: true } });
        }

        if (validation.isValid) {
            POST(url, user)
                .then(response => response.json())
                .then(results => {
                    const data = extractData(results);
                    if (data === null || data === undefined) this.setState({ errorMessage: results.message || SUBMIT_ERROR_MESSAGE, loading: false });
                    else {
                        this.props.history.push({
                            pathname: '/company/users',
                            state: { newUserSuccessMessage: `Successfully added the user ${user.forename} ${user.surname}` }
                        });
                    }
                }).catch(() => this.setState({ errorMessage: SUBMIT_ERROR_MESSAGE, loading: false }));
        } else this.setState({ loading: validation.isValid, errorMessage: VALIDATION_ERROR_MESSAGE });

    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;
        passwordInputType === "password" ? this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) : this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    render() {
        const { loading, submitted, errorMessage, user, validationMessages, passwordNumberCheck, passwordSpecialCharCheck, passwordLengthCheck, passwordInputType, passwordInputIcon } = this.state;
        const validation = submitted ? this.validate(user) : this.state.validation;

        if (submitted) {
            if (user.roles.length === 0) {
                validation.isValid = false;
                validationMessages.roles = true;
            } else validationMessages.roles = false;
        }

        return (
            <div className="center-block mt-5">
                {loading
                    ? <LoadingBar />
                    : <React.Fragment>
                        <Tab title="Users" subNavList={UsersSubNav} activeKey="Add New User" />
                        <h2>{user.id ? 'Edit User' : 'Add New User'}</h2>
                        <Row>
                            <Col sm={12} md={6}>
                                {errorMessage.length > 0 ? <ErrorAlert messages={errorMessage} /> : null}
                                <Form onSubmit={this.handleSubmit} className="mt-4">
                                    <input type="hidden" name="id" value={user.id} />
                                    <h3>User details</h3>
                                    <fieldset>
                                        <FormGroup>
                                            <FormLabel htmlFor="forename">First Name</FormLabel>
                                            <FormControl maxLength="25" id="forename" type="text" name="forename" value={user.forename} onChange={this.handleInputChange} />
                                            <span className="text-danger">{validation.forename.message}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel htmlFor="surname">Last Name</FormLabel>
                                            <FormControl maxLength="25" id="surname" type="text" name="surname" value={user.surname} onChange={this.handleInputChange} />
                                            <span className="text-danger">{validation.surname.message}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel htmlFor="email">Email</FormLabel>
                                            <FormControl maxLength="50" id="email" type="email" name="email" autoComplete="off" value={user.email} onChange={this.handleInputChange} />
                                            <span className="text-danger">{validation.email.message}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel htmlFor="password">Password</FormLabel>
                                            <FormControl maxLength="50" id="password" type={passwordInputType} name="password" autoComplete="new-password" value={user.password} onChange={this.handleInputChange} />
                                            <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
                                            <span className="text-danger">{validation.password.message}</span>
                                        </FormGroup>
                                        <div className="form-password-checklist">
                                            <ul>
                                                <li className={passwordLengthCheck ? "pass" : "fail"}>Six characters long</li>
                                                <li className={passwordNumberCheck ? "pass" : "fail"}>One number</li>
                                                <li className={passwordSpecialCharCheck ? "pass" : "fail"}>One special character</li>
                                            </ul>
                                        </div>
                                    </fieldset>

                                    {Object.keys(this.state.roleOptions).map((k) =>
                                        <div key={k} className="py-2">
                                            <h3 className="pb-2">{k} Roles</h3>
                                            {validationMessages.roles && <span className="text-danger">{ROLE_VALIDATION_MESSAGE}</span>}
                                            {this.state.roleOptions[k].map((r) =>
                                                <FormGroup key={r.id} className="custom-control custom-checkbox mb-1">
                                                    <input className="custom-control-input" id={r.id} type="checkbox" name={r.id} onChange={this.handleInputChange} checked={user.roles.indexOf(r.id) > -1} />
                                                    <FormLabel className="custom-control-label" htmlFor={r.id}><p>{r.name}</p></FormLabel>
                                                </FormGroup>
                                            )}
                                        </div>
                                    )}

                                    <FormGroup className="float-right mt-4">
                                        <Link to='/company/users/'>
                                            <Button variant="link" className="mr-3">Cancel</Button>
                                        </Link>
                                        <Button variant="primary" type="submit">Add User</Button>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    </React.Fragment>
                }
            </div>

        );
    }
}

export default AddUser;