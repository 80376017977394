import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import './SubNav.scss'

const SubNav = ({ breadcrumbs, activeKey }) =>
    breadcrumbs.length ? (
        <Breadcrumb>
            {breadcrumbs.map((breadcrumb, i) => breadcrumb.text === activeKey ?
                <Breadcrumb.Item key={'link-active-' + i} active>
                    <span className="active">{breadcrumb.text}</span>
                </Breadcrumb.Item> :
                <LinkContainer key={'link-' + i} to={breadcrumb.link}>
                    <Breadcrumb.Item>
                        {breadcrumb.text}
                    </Breadcrumb.Item>
                </LinkContainer>)
            }
        </Breadcrumb>
    ) : null;

export default SubNav;