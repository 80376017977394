import React from 'react';
import { Row, Col, Button, InputGroup, FormControl, Dropdown, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './Snippets.scss';

const UsersFilter = ({ name, sortBy = "", onSearch, onFilterChange, onClear }) =>
    <React.Fragment>
        <Row className="form-group-filter mt-3">
            <Col sm={12} md={4}>
                <InputGroup>
                    <FormControl type="text" name="name" placeholder={"Search by name..."} value={name} onChange={onFilterChange} min="4" />
                    <InputGroup.Append>
                        <InputGroup.Text id="nameField" className="input-filter-icon">
                            <FontAwesomeIcon icon={faSearch} size="xs" />
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            <Col sm={12} md={3}>
                <FormControl id="sortBy" as="select" name="sortBy" value={sortBy} onChange={onFilterChange}>
                    <option key="" value="" >Sort by...</option>;
                    <option value="EMAIL">Email</option>
                    <option value="FULL_NAME">Full Name</option>
                    <option value="LAST_LOGIN">Last Login</option>
                    <option value="STATUS">Status</option>
                </FormControl>
            </Col>
        </Row>
        <div className="button-group-filter mb-3">
            <Button variant="primary" className="float-right ml-3" onClick={onSearch}>Apply Filters</Button>
            <Button variant="link" className="float-right link-button" onClick={onClear}>Clear Filters</Button>
        </div>
    </React.Fragment>

const Actions = ({ actions }) =>
    <div className="action-container">
        <Dropdown className="table-dropdown-action">
            <Dropdown.Toggle data-boundary="viewport" className="dropdown-toggle-action">...</Dropdown.Toggle>
            <Dropdown.Menu>
                {actions.length && actions.map((a, i) => a && <Dropdown.Item key={`action-${i}`} className={a.danger ? "danger" : ""}>{a.action}</Dropdown.Item>)}
            </Dropdown.Menu>
        </Dropdown>
    </div>

const UserPermissions = ({ userPermissions, editLink }) =>
    <Card>
        <Card.Header><strong>Permissions</strong></Card.Header>
        <Card.Body>
            {userPermissions.length ? userPermissions.map((parent) =>
                <React.Fragment>
                    <Card.Text key={`${parent.name}-permissions`}><strong>{parent.name}</strong></Card.Text>
                    {parent.permissions.length ? parent.permissions.filter((permission) => permission.enabled === true).map((permission) =>
                        <Card.Text>{permission.featureEnumName}</Card.Text>
                    ) : null}
                </React.Fragment>
            ) : null}
            {editLink && editLink}
        </Card.Body>
    </Card>


export {
    UsersFilter,
    Actions,
    UserPermissions
}