import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Button, Badge } from 'react-bootstrap';
import { GET, PUT, extractData } from '../../Consumer';
import { ReturnTableHeaders, ReturnsSubNav } from '../common/Constants';
import LoadingBar from '../common/LoadingBar';
import Endpoints from '../common/Endpoints';
import PagingTable from '../common/tables/PagingTable';
import Tab from '../common/navigation/Tab';
import { GridErrorAlert, GridInfoAlert } from '../common/Alerts';
import { toLocalTimeString } from '../../Utilities';

const InProcess = ({ currentlySelectedWarehouse }) => {

    //#region State

    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const [pageIndex, setPageIndex] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    const [rows, setRows] = useState([]);

    const [renderCount, setRenderCount] = useState(0);

    //#endregion

    //#region Actions

    const markPrinted = useCallback(async (id) => {
        try {
            const urlString = Endpoints.FULFILMENT.PUT.MARK_AS_PRINT_RETURN.replace("{id}",id);
            const url = new URL(currentlySelectedWarehouse !== '' ? `${urlString}/${currentlySelectedWarehouse}` : urlString);

            const response = await PUT(url);
            if (response?.ok) {
                const responseData = await response.json();
                if (responseData) {
                    setRenderCount(currentCount => currentCount + 1);
                }
                setShowError(false);
            }
            else {
                setShowError(true);
            }
        } catch (error) {
            console.log(error);
            setShowError(true);
        }
    }, [currentlySelectedWarehouse]);

    const download = useCallback(async (id, returnCheckInRunItemID) => {
        setLoading(true);
        try {
            const urlString = Endpoints.FULFILMENT.GET.RETURN_LABEL.replace("{id}", id);
            const url = new URL(currentlySelectedWarehouse !== '' ? `${urlString}/${currentlySelectedWarehouse}` : urlString);

            const response = await GET(url);
            if (response?.ok) {
                const manifestBlob = await response.blob();
                const objectURL = URL.createObjectURL(manifestBlob);
                window.open(objectURL);
                await markPrinted(returnCheckInRunItemID);
                setShowError(false);
            }
            else {
                setShowError(true);
            }
        } catch (e) {
            console.log(e);
            setShowError(false);
        } finally {
            setLoading(false);
        }
    }, [currentlySelectedWarehouse, markPrinted]);

    //#endregion

    //#region API

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const url = new URL(currentlySelectedWarehouse !== '' ? `${Endpoints.FULFILMENT.GET.RETURNS}/${currentlySelectedWarehouse}` 
                    : Endpoints.FULFILMENT.GET.RETURNS);

                url.searchParams.append("pageIndex", pageIndex);

                const response = await GET(url);
                if (response?.ok) {
                    const responseData = await response.json();
                    const { returnItemLabels, pageCount} = extractData(responseData);
                    const returnRows = [];

                    returnItemLabels.forEach(r => returnRows.push([
                        <div>
                            {r.returnReference}
                        </div>,
                        <div>
                            {r.productName}
                        </div>,
                        toLocalTimeString(r.createdDate),
                        <div>
                            {r.printed ? <Badge className="green">Printed</Badge> : <Badge className="grey">Not Printed</Badge>}
                        </div>,
                        <div>
                            {r.url && <div className="my-2"><Button variant="secondary" onClick={() => download(r.id, r.returnCheckInRunItemID)}>Print</Button></div>}
                        </div>
                    ]));

                    setRows(returnRows);
                    setPageCount(pageCount);
                } else {
                    setShowError(true);
                }
            } catch (error) {
                console.log({ error });
                setShowError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, [pageIndex, download, renderCount, currentlySelectedWarehouse]);

    //#endregion

    //#region Controls

    const onNext = () => {
        if (pageIndex < pageCount) setPageIndex((index) => index + 1);
    };

    const onPrevious = () => {
        if (pageIndex > 1) setPageIndex((index) => index - 1);
    };

    const onStart = () => setPageIndex(1);
    const onEnd = () => setPageIndex(pageCount);

    //#endregion

    return (
        loading
            ? <LoadingBar />
            : <div className="center-block mt-5">
                <Tab title="Returns" subNavList={ReturnsSubNav} activeKey="Returns In Process" />
                {showError
                    ? <GridErrorAlert message="Could not retrieve returns for this warehouse." />
                    : <Fragment>
                        {rows.length === 0 ? <GridInfoAlert message="There are no returns at this time." />
                        : <PagingTable
                            headers={ReturnTableHeaders}
                            rows={rows}
                            onNext={onNext}
                            onPrevious={onPrevious}
                            onStart={onStart}
                            onEnd={onEnd}
                            pageIndex={pageIndex}
                            pageCount={pageCount} />}
                    </Fragment>
                }
            </div>

    );
}

export default InProcess;