import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Confirm from '../components/common/modals/Confirm';
import { Form, FormControl } from 'react-bootstrap';
import { hasMultipleWarehouseAccess } from './../Utilities';

import './Header.scss';

class Header extends Component {

    state = {
        showLogoutModal: false
    };

    logout = () => {
        this.setState({ showLogoutModal: false });
        this.props.onLogout();
    }

    handleShowHideLogout = () => {
        const { showLogoutModal } = this.state;

        showLogoutModal === true ?
            this.setState({ showLogoutModal: false }) :
            this.setState({ showLogoutModal: true });
    }

    render() {
        const { showLogoutModal } = this.state;
        const { currentlySelectedWarehouse, availableWarehouses, handleWarehouseChange } = this.props;
        const showWarehouseOptions = availableWarehouses !== [] && hasMultipleWarehouseAccess()
        return (
            <React.Fragment>
                <Navbar className="shadow-sm fixed-top">
                    <Navbar.Brand className="mx-4">
                        <img alt="Selazar Logo" src="/Images/logo.png" className="d-inline-block align-top" />
                    </Navbar.Brand>
                    {showWarehouseOptions &&
                        <Form className="warehouse-selector">
                            <FormControl as="select" id="identifier" name="identifier" value={currentlySelectedWarehouse} onChange={handleWarehouseChange}>
                                <option name="" value="">Select Warehouse</option>
                                {availableWarehouses.map((v, i) => <option key={i} value={v.identifier}>{v.name}</option>)}
                            </FormControl>
                        </Form> }
                    <Nav className="ml-auto ">
                        <Nav.Link onClick={this.handleShowHideLogout}>Sign Out <FontAwesomeIcon className="ml-1" icon={faSignOutAlt} /></Nav.Link>
                    </Nav>
                </Navbar>
                <Confirm
                    title={"Log Out"}
                    text={"Are you sure you want to log out?"}
                    show={showLogoutModal}
                    handleClose={this.handleShowHideLogout}
                    handleConfirmAction={this.logout}
                    handleCancelAction={this.handleShowHideLogout}
                    buttonText="Log out"
                    linkText="Stay logged in" />
            </React.Fragment>
        );
    }
}

export default Header;