import React, { Fragment, useState, useEffect } from "react";
import Tab from "../common/navigation/Tab";
import { ReturnsSubNav, ReturnIssueTableHeaders } from "../common/Constants";
import { toLocalTimeString, checkPermission } from '../../Utilities';
import LoadingBar from "../common/LoadingBar";
import { GET, PUT } from '../../Consumer';
import Endpoints from "../common/Endpoints";
import { GridErrorAlert, GridInfoAlert } from '../common/Alerts';
import PagingTable from "../common/tables/PagingTable";
import GenericModal from '../common/modals/GenericModal';
import { Button } from "react-bootstrap";

const RetailerReturnIssues = ({ currentlySelectedWarehouse }) => {
  //#region State
  const [loading, setLoading] = useState(false);
  const [fetchDataLoading, setFetchDataLoading] = useState(true);
  const [resolveIssueLoading, setResolveIssueLoading] = useState(false)
  const [showError, setShowError] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [rows, setRows] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedIssueID, setSelectedIssueID] = useState(null);
  const [renders, setRenders] = useState(0);

  useEffect(() => {
    setLoading(fetchDataLoading || resolveIssueLoading)
  }, [fetchDataLoading, resolveIssueLoading])

  //#endregion

  //#region API

  /**
   * Fetch data
   */
  useEffect(() => {
      (async () => {
        setFetchDataLoading(true);
        try {
          const url = new URL(currentlySelectedWarehouse !== '' ? `${Endpoints.FULFILMENT.GET.RETURN_ISSUES}/${currentlySelectedWarehouse}`
             : Endpoints.FULFILMENT.GET.RETURN_ISSUES);

          url.searchParams.append("pageIndex", pageIndex);
          const response = await GET(url);

          if (response?.ok) {
            setShowError(false);
            const {
              data: { pageCount, retailerReturnIssues },
            } = await response.json();
            const canResolveReturnIssues = checkPermission('ResolveReturnIssues');
            
            var rows = [];
            retailerReturnIssues.forEach((issue) => {
              rows.push([
                issue.returnReference,
                issue.orderReference,
                toLocalTimeString(issue.dateReported),
                <div>{canResolveReturnIssues && <Button variant="secondary" className="m-0" onClick={() => handleShowModal(issue.id)}>Resolve Issue</Button>}</div>,
              ]);
            });
            setRows(rows);
            setPageCount(pageCount);
          } else {
            setShowError(true);
          }
        } catch (error) {
          console.log({ error });
          setShowError(true);
        } finally {
          setFetchDataLoading(false);
        }
      })();
  }, [currentlySelectedWarehouse, pageIndex, renders]);

  //#endregion

  //#region Controls

  const handleShowModal = (issueID) => {
    setShowConfirmModal(true);
    setSelectedIssueID(issueID);
  }

  const handleModalConfirm  = async () => handleConfirmAction(true);
  const handleModalReject  = async () => handleConfirmAction(false);
  const onNext = () => { if (pageIndex < pageCount) setPageIndex((index) => index + 1); };
  const onPrevious = () => { if (pageIndex > 1) setPageIndex((index) => index - 1); };
  const onStart = () => setPageIndex(1);
  const onEnd = () => setPageIndex(pageCount);

  const handleConfirmAction = async (confirmed) => {
    setResolveIssueLoading(true);

    const urlString = Endpoints.FULFILMENT.PUT.CONFIRM_RETURN_ISSUE.replace("{id}", selectedIssueID).replace("{confirmed}", confirmed)
    const url = new URL(currentlySelectedWarehouse !== '' ? `${urlString}/${currentlySelectedWarehouse}` : urlString);

    try {
      const response = await PUT(url);

      if (response?.ok) {
        const responseData = await response.json();

        if (responseData.data === true) {
          setSelectedIssueID(null);
          setShowError(false);
          setRenders((currentValue) => currentValue + 1 )
        } else { setShowError(true); }
      } else { setShowError(true); }
    } catch (error) {
      console.log({ error });
      setShowError(true);
    } finally {
      setShowConfirmModal(false);
      setResolveIssueLoading(false);
    }
  }

  //#endregion

  return (
    <div className="center-block mt-5">
      {loading ? (
        <LoadingBar />
      ) : (
        <>
          <Tab title="Returns" subNavList={ReturnsSubNav} activeKey="Issues" />
          {showError ? <GridErrorAlert message="Could not retrieve return issues for this warehouse." /> 
          : <Fragment>
              {rows.length === 0 ? <GridInfoAlert message="There are no issues at this time." />
              : <PagingTable
                  headers={ReturnIssueTableHeaders}
                  rows={rows}
                  onNext={onNext}
                  onPrevious={onPrevious}
                  onStart={onStart}
                  onEnd={onEnd}
                  pageIndex={pageIndex}
                  pageCount={pageCount} />}
                <GenericModal
                  title="Return Issue"
                  children="Is there an issue with this return?"
                  show={showConfirmModal}
                  primaryAction={handleModalConfirm}
                  primaryActionText="Yes, there is an issue"
                  secondaryAction={handleModalReject}
                  secondaryActionText="No, there isn't an issue"
                  handleClose={ () => setShowConfirmModal(false) }
                />
            </Fragment>
          }
        </>
      )}
    </div>
  );
};

export default RetailerReturnIssues;