import React, { Component } from 'react';
import { Row, Col, Button, Badge } from 'react-bootstrap';
import { checkPermission, toLocalTimeString } from '../../Utilities';
import { GET, PUT, DELETE, extractData } from '../../Consumer';
import Endpoints from '../../components/common/Endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import LoadingBar from '../../components/common/LoadingBar';
import { GridErrorAlert } from '../../components/common/Alerts';
import Confirm from '../common/modals/Confirm';

const USER_ERROR_MESSAGE = 'Could not retrieve account information';
const ROLES_ERROR_MESSAGE = 'Could not retrieve roles';
const DELETE_ERROR_MESSAGE = 'Could not delete user';
const TOGGLE_ERROR_MESSAGE = 'Could not toggle user';

const UserDetails = ({ name, email, roleOptions, roles, id, handleEditDetails }) =>
    <section className="mt-2">
        <h3>User Details</h3>
        <p className="section-title">Users Name</p>
        <p>{name}</p>
        <p className="section-title">Email</p>
        <p>{email}</p>
        <p className="section-title">Fulfilment Roles</p>
        {Object.keys(roleOptions).map(o =>
            <div key={o} className="mb-2">
                {roleOptions[o].map((r, i) =>
                    roles.indexOf(r.id) > -1 && <p key={`${r.name}-${i}`}>{r.name}</p>
                )}
            </div>
        )}
        {checkPermission('EditUser') && <a onClick={() => handleEditDetails(id)} className="inline-link">Edit User Details</a>}
    </section>

const AccountDetails = ({ active, lastLogin, id, isSameUser, handleToggleModalShowHide, handleDeleteModalShowHide, handleChangePassword }) =>
    <section className="mt-2">
        <h3>Account Details</h3>
        <p className="section-title">Status</p>
        {active ? <Badge className="green">Active</Badge> : <Badge className="red">Deactivated</Badge>}
        <p className="section-title">Last Login</p>
        {lastLogin ? <p>{toLocalTimeString(lastLogin)}</p> : <p>No Logins</p>}

        <div className="account-action-container">
            <Button variant="secondary" className="d-block mb-3" onClick={() => handleChangePassword(id)}>Change Password</Button>
            {!isSameUser && checkPermission('ToggleUser') && <Button variant={active ? "danger" : "secondary"} className="d-block mb-3" onClick={handleToggleModalShowHide}>{active ? "Deactivate User" : "Activate User"}</Button>}
            {!isSameUser && checkPermission('DeleteUser') && <Button variant="danger" className="d-block mb-3" onClick={handleDeleteModalShowHide}>Remove User</Button>}
        </div>
    </section>

class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            userID: props.match.params.id || null,
            roleOptions: {},
            userPermissions: [],
            redirect: false,
            showDeleteUserConfirmation: false,
            showToggleUserConfirmation: false,
            user: {
                id: '',
                forename: '',
                surname: '',
                email: '',
                active: true,
                roles: [],
                createdDate: null
            },
            errorMessage: ''
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchUser(),
            this.fetchRoleOptions()
        ]);
    }

    componentDidUpdate = (prevProps) => this.props.currentlySelectedWarehouse !== prevProps.currentlySelectedWarehouse && this.props.history.push('/company/users');

    fetchUser = () => {
        const { userID } = this.state;
        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== ''
            ? Endpoints.WMS_ADMIN.USER.GET.GET_USER_WITH_ROLES.replace("{warehouseid}", currentlySelectedWarehouse).replace("{id}", userID)
            : Endpoints.USER.GET.GET_USER_WITH_ROLES.replace("{id}", userID));

        return GET(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ errorMessage: USER_ERROR_MESSAGE, loading: false });
                else {
                    const user = { ...data, roles: data.userRoles.map((ur) => ur.roleID) };
                    this.setState({ user: user, loading: false });
                }
            }).catch(() => this.setState({ showError: true, errorMessage: USER_ERROR_MESSAGE, loading: false }));
    }

    fetchRoleOptions = () => {
        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== ''
            ? Endpoints.WMS_ADMIN.USER.GET.GET_ROLE_OPTIONS_FOR_COMPANY.replace("{warehouseid}", currentlySelectedWarehouse)
            : Endpoints.USER.GET.GET_ROLE_OPTIONS_FOR_COMPANY);

        return GET(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ errorMessage: ROLES_ERROR_MESSAGE, loading: false });
                else this.setState({ roleOptions: data, loading: false });
            }).catch(() => this.setState({ errorMessage: ROLES_ERROR_MESSAGE, loading: false }));
    }

    handleToggleModalShowHide = () => this.setState((prevState) => ({ showToggleUserConfirmation: !prevState.showToggleUserConfirmation }));
    handleDeleteModalShowHide = () => this.setState((prevState) => ({ showDeleteUserConfirmation: !prevState.showDeleteUserConfirmation }));

    handleToggleAction = () => {
        const { userID, user } = this.state;
        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== '' ? Endpoints.WMS_ADMIN.USER.PUT.TOGGLE_USER.replace("{warehouseid}", currentlySelectedWarehouse).replace("{id}", userID) : Endpoints.USER.PUT.TOGGLE_USER.replace("{id}", userID));

        if (Object.keys(user).indexOf('id') === -1) {
            this.setState({ errorMessage: TOGGLE_ERROR_MESSAGE });
            return;
        }

        this.setState({ loading: true, showToggleUserConfirmation: false });

        return PUT(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ loading: false, errorMessage: TOGGLE_ERROR_MESSAGE });
                else this.fetchUser();
            }).catch(() => this.setState({ loading: false, errorMessage: TOGGLE_ERROR_MESSAGE }));
    }

    handleDeleteAction = () => {
        const { user } = this.state;
        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== '' ? Endpoints.WMS_ADMIN.USER.DELETE.DELETE_USER.replace("{warehouseid}", currentlySelectedWarehouse).replace("{id}", user.id) : Endpoints.USER.DELETE.DELETE_USER.replace("{id}", user.id));

        if (Object.keys(user).indexOf('id') === -1) {
            this.setState({ errorMessage: DELETE_ERROR_MESSAGE });
            return;
        }

        this.setState({ loading: true, showDeleteUserConfirmation: true });

        return DELETE(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ loading: false, errorMessage: DELETE_ERROR_MESSAGE });
                else this.setState({ loading: false, redirect: true, errorMessage: '' });
            }).catch(() => this.setState({ loading: false, errorMessage: DELETE_ERROR_MESSAGE }));
    }

    handleBack = () => this.props.history.push(`/company/users/`);

    handleEditDetails = (userID) => this.props.history.push({ pathname: `/company/users/account/details/${userID}`, state: { showTabEditUser: false } });

    handleChangePassword = (userID) => {
        const { user } = this.state;
        this.props.history.push({
            pathname: `/company/users/account/password/${userID}`,
            state: { forename: user.forename, surname: user.surname }
        });
    }

    handleDeleteUser = () => {
        const { user } = this.state;
        this.props.history.push({
            pathname: `/company/users`,
            state: { showRemovalSuccess: true, successMessage: 'Successfully removed the user ' + user.forename + ' ' + user.surname }
        });
    }

    render() {
        const { user, loading, redirect, roleOptions, errorMessage, showToggleUserConfirmation, showDeleteUserConfirmation } = this.state;
        const toggleMessage = `Are you sure you want to ${user.active ? 'deactivate' : 'activate'} ${user.forename} ${user.surname}?`;
        const deleteMessage = `Are you sure you want to delete ${user.forename} ${user.surname}?`

        const currentUser = JSON.parse(localStorage.getItem('user'));
        const isSameUser = currentUser.id === user.id;

        return (
            loading
                ? <LoadingBar />
                : <div className="center-block mt-5">
                    <h1 className="mb-3">Users</h1>
                    <div className="breadcrumb-container">
                        <a onClick={this.handleBack} className="pr-2 inline-link">Users</a>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span className="p-2">View User Account</span>
                    </div>
                    {redirect
                        ? this.handleDeleteUser()
                        : false
                            ? <GridErrorAlert message={errorMessage} />
                            : <React.Fragment>
                                <h2>View User Account</h2>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <UserDetails name={`${user.forename} ${user.surname}`} email={user.email} roleOptions={roleOptions} roles={user.roles} id={user.id} handleEditDetails={this.handleEditDetails} />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <AccountDetails active={user.active} lastLogin={user.lastLogin} id={user.id} isSameUser={isSameUser} handleToggleModalShowHide={this.handleToggleModalShowHide} handleDeleteModalShowHide={this.handleDeleteModalShowHide} handleChangePassword={this.handleChangePassword} />
                                    </Col>
                                </Row>

                                <Confirm
                                    title={user.active ? "Confirm Deactivation" : "Confirm Activation"}
                                    text={toggleMessage}
                                    handleClose={this.handleToggleModalShowHide}
                                    show={showToggleUserConfirmation}
                                    handleConfirmAction={this.handleToggleAction}
                                    buttonText={user.active ? "Deactivate" : "Activate"}
                                    variant={user.active ? "danger" : "primary"}
                                />
                                <Confirm
                                    title="Confirm Removal"
                                    text={deleteMessage}
                                    handleClose={this.handleDeleteModalShowHide}
                                    show={showDeleteUserConfirmation}
                                    handleConfirmAction={this.handleDeleteAction}
                                    buttonText="Remove"
                                    variant="danger"
                                />
                            </React.Fragment>
                    }
                </div>
        );
    }
}

export default Account;