import React, { Component } from 'react';
import { Button, Badge } from 'react-bootstrap';
import { GET, extractData } from '../../Consumer';
import LoadingBar from '../common/LoadingBar';
import Endpoints from '../common/Endpoints';
import { GridErrorAlert, GridInfoAlert } from '../../components/common/Alerts';
import { FulfilmentLabelTableHeaders } from '../common/Constants'
import { toLocalTimeString, goToURL } from '../../Utilities';
import PagingTable from '../common/tables/PagingTable';

class FulfilmentLabels extends Component {

    state = {
        labels: [],
        loading: true,
        pageIndex: 1,
        pageCount: 1,
        error: false
    };

    async componentDidMount() {
        await this.fetchLabels();
        setInterval(this.fetchLabels, 30000); // runs every 30 seconds.
    }

    fetchLabels = () => {
        const { currentlySelectedWarehouse } = this.props;

        const url = new URL(currentlySelectedWarehouse !== '' ? Endpoints.FULFILMENT.GET.LABELS + "/" + currentlySelectedWarehouse : Endpoints.FULFILMENT.GET.LABELS);
        url.searchParams.append("pageIndex", this.state.pageIndex);

        return GET(url)
            .catch(error => console.log(error))
            .then(response => { if (response.ok) return response.json(); })
            .then(result => {
                const data = extractData(result);
                data ? this.setState({ labels: data.labels, pageCount: data.pageCount, loading: false, error: false })
                    : this.setState({ labels: [], loading: false, error: true });
            }).catch(() => this.setState({ labels: [], loading: false, error: true }));
    }

    getTableRows = () => {
        const { labels } = this.state;
        const labelRows = [];

        labels.forEach(label => labelRows.push([
            label.displayName,
            toLocalTimeString(label.createdDate),
            <div>
                {label.packingSlipUrl && <div className="my-2"><Button variant="secondary" onClick={() => { goToURL(label.packingSlipUrl) }}>Download Packing Slip</Button></div>}
                {label.packingSlipUrl && label.packingSlipPrinted ? <Badge className="green">Printed</Badge> : label.packingSlipUrl && <Badge className="grey">Not Printed</Badge>}
            </div>,
            <div>
                {label.url && <div className="my-2"><Button variant="secondary" onClick={() => { goToURL(label.url) }}>Download Labels</Button></div>}
                {label.printed ? <Badge className="green">Printed</Badge> : <Badge className="grey">Not Printed</Badge>}
            </div>
        ]));

        return labelRows;
    }

    onNext = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.fetchLabels);
    }

    onPrevious = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.fetchLabels);
    }

    onStart = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) this.setState({ pageIndex: 1, loading: true }, this.fetchLabels);
    }

    onEnd = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) { this.setState({ pageIndex: pageCount, loading: true }, this.fetchLabels); }
    }

    render() {
        const { loading, labels, pageIndex, pageCount, error } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <div className="center-block mt-5">
                    <h1 className="mb-3">Fulfilment</h1>
                    {error
                        ? <GridErrorAlert message="Could not retrieve fulfilment labels for this warehouse." />
                        : <React.Fragment>
                            {labels.length === 0 && <GridInfoAlert message="There are no fulfilment labels at this time." />}
                            <PagingTable
                                headers={FulfilmentLabelTableHeaders}
                                rows={this.getTableRows()}
                                onNext={this.onNext}
                                onPrevious={this.onPrevious}
                                onStart={this.onStart}
                                onEnd={this.onEnd}
                                pageIndex={pageIndex}
                                pageCount={pageCount} />
                        </React.Fragment>
                    }
                </div>
        );
    }
}

export default FulfilmentLabels;