import React from 'react';
import { Container } from 'react-bootstrap';
import Logo from '../common/Logo';

const CardPage = ({ content }) => (
    <div className="center container-card-page">
        <Container>
            <Logo />
            <h3 className="d-block text-center mt-2 title">Warehouse Management System</h3>
            <div className="center-block pb-5">
                {content}
            </div>
        </Container>
    </div>
);

export default CardPage;
