import React, { Component } from 'react';
import { Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUserTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { extractData, GET, DELETE, PUT } from '../../Consumer';
import { checkPermission, toLocalTimeString, setFilterData } from '../../Utilities';
import Endpoints from '../common/Endpoints';
import LoadingBar from '../common/LoadingBar';
import { GridErrorAlert, GridSuccessAlert, NoResultsInfoAlert } from '../common/Alerts';
import { UserTableHeaders, UsersSubNav } from '../common/Constants'
import PagingTable from '../common/tables/PagingTable';
import Tab from '../common/navigation/Tab';
import Confirm from '../common/modals/Confirm';
import { UsersFilter, Actions } from './Snippets';

const DELETION_ERROR = 'Could not delete user';
const TOGGLE_ERROR = 'Could not toggle user';

class UserListing extends Component {

    state = {
        loading: true,
        totalCount: 0,
        pageIndex: 1,
        pageCount: 1,
        users: [],
        showDeleteUserConfirmation: false,
        showToggleUserConfirmation: false,
        selectedUser: null,
        toggleMessage: '',
        deleteMessage: '',
        filter: {
            name: '',
            sortBy: ''
        },
        showUserRetrievalError: false,
        showError: false,
        errorMessage: '',
        successMessage: '',
        showRemovalSuccess: false
    };

    componentDidMount = () => this.fetchUsers();

    componentDidUpdate = (prevProps) => this.props.currentlySelectedWarehouse !== prevProps.currentlySelectedWarehouse && this.fetchUsers();

    handletoggleModalShowHide = (user) => user
        ? this.setState(prevState => ({ showToggleUserConfirmation: !prevState.showToggleUserConfirmation, selectedUser: user, toggleMessage: `Are you sure you want to ${user.active ? 'deactivate' : 'activate'} ${user.forename} ${user.surname}?` }))
        : this.setState(prevState => ({ showToggleUserConfirmation: !prevState.showToggleUserConfirmation }));


    handleDeleteModalShowHide = (user) => user
        ? this.setState(prevState => ({ showDeleteUserConfirmation: !prevState.showDeleteUserConfirmation, selectedUser: user, deleteMessage: `Are you sure you want to remove ${user.forename} ${user.surname}?` }))
        : this.setState(prevState => ({ showDeleteUserConfirmation: !prevState.showDeleteUserConfirmation }));

    onNext = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.fetchUsers);
    }

    onPrevious = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.fetchUsers);
    }

    onStart = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) this.setState({ pageIndex: 1, loading: true }, this.fetchUsers);
    }

    onEnd = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) this.setState({ pageIndex: parseInt(pageCount), loading: true }, this.fetchUsers);
    }

    handleOnPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchUsers);
    }

    handleOnFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    handleOnClear = () => {
        this.setState({
            users: [],
            loading: true,
            filter: {
                name: '',
                sortBy: ''
            }
        }, this.fetchUsers);
    }

    handleOnSearch = (e) => {
        e.preventDefault();
        this.setState({ users: [], loading: true }, this.fetchUsers);
    }

    handleEditUserRedirect = (url) => {
        this.props.history.push({
            pathname: url,
            state: { showTabEditUser: true }
        });
    }

    handleRedirect = (url) => {
        const { pageIndex, filter } = this.state;
        setFilterData("users", { pageIndex: pageIndex, search: filter.search, user: filter.user });
        this.props.history.push(url);
    }

    getActions = (user) => {
        const editUser = checkPermission('EditUser') && <Button onClick={() => this.handleEditUserRedirect(`/company/users/account/details/${user.id}`)} variant="link"><FontAwesomeIcon icon={faEdit} />Edit Details</Button>;
       
        const currentUser = JSON.parse(localStorage.getItem('user'));
        const isSameUser = currentUser.id === user.id;

        const toggleUser = !isSameUser && checkPermission('ToggleUser') && <Button variant="link" onClick={() => this.handletoggleModalShowHide(user)}><FontAwesomeIcon icon={faUserTimes} />{user.active ? "Deactivate" : "Activate"}</Button>;
        const deleteUser = !isSameUser && checkPermission('DeleteUser') && <Button variant="link" className="action-danger" onClick={() => this.handleDeleteModalShowHide(user)}><FontAwesomeIcon icon={faTrashAlt} />Remove</Button>;

        const rowActions = [];
        rowActions.push({ action: editUser, danger: false });
        toggleUser && rowActions.push({ action: toggleUser, danger: false });
        deleteUser && rowActions.push({ action: deleteUser, danger: true });

        return rowActions;
    }

    getUserRows = () => {
        const { users } = this.state;
        const userRows = [];

        users.forEach(user => userRows.push([
            `${user.forename} ${user.surname}`,
            user.email,
            user.lastLogin ? toLocalTimeString(user.lastLogin) : "No Logins",
            user.active ? <Badge className="green">Active</Badge> : <Badge className="red">Deactivated</Badge>,
            checkPermission("ViewUser") && <Button variant="secondary" className="m-0" onClick={() => this.handleRedirect(`/company/users/account/overview/${user.id}`)}>View Details</Button>,
            <Actions actions={this.getActions(user)} />
        ]));

        return userRows;
    }

    fetchUsers = () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        const { currentlySelectedWarehouse } = this.props;

        const url = new URL(currentlySelectedWarehouse !== ''
            ? Endpoints.WMS_ADMIN.USER.GET.GET_FILTERED_USERS.replace("{warehouseid}", currentlySelectedWarehouse)
            : Endpoints.USER.GET.GET_FILTERED_USERS);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex);

        return GET(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ users: [], loading: false, totalCount: 0, pageCount: 1, showUserRetrievalError: true });
                else this.setState({ users: data.users, totalCount: data.total, pageCount: data.pageCount, showUserRetrievalError: false, loading: false });
            }).catch(() => this.setState({ users: [], loading: false, totalCount: 0, pageCount: 1, showUserRetrievalError: true }));
    }

    handleToggleAction = () => {
        const { selectedUser } = this.state;
        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== ''
            ? Endpoints.WMS_ADMIN.USER.PUT.TOGGLE_USER.replace("{warehouseid}", currentlySelectedWarehouse).replace("{id}", selectedUser.id)
            : Endpoints.USER.PUT.TOGGLE_USER.replace("{id}", selectedUser.id));

        if (Object.keys(selectedUser).indexOf('id') === -1) {
            this.setState({ errorMessage: TOGGLE_ERROR, showError: true });
            return;
        }

        this.setState({ loading: true, showToggleUserConfirmation: false });

        return PUT(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ loading: false, showError: true, errorMessage: TOGGLE_ERROR });
                else {
                    this.setState({ showError: false, loading: false, errorMessage: '' });
                    this.fetchUsers();
                }
            }).catch(() => this.setState({ showError: true, loading: false, errorMessage: TOGGLE_ERROR }));
    }

    handleDeleteAction = () => {
        const { selectedUser } = this.state;
        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== ''
            ? Endpoints.WMS_ADMIN.USER.DELETE.DELETE_USER.replace("{warehouseid}", currentlySelectedWarehouse).replace("{id}", selectedUser.id)
            : Endpoints.USER.DELETE.DELETE_USER.replace("{id}", selectedUser.id));

        if (Object.keys(selectedUser).indexOf('id') === -1) {
            this.setState({ errorMessage: DELETION_ERROR, showError: true });
            return;
        }

        this.setState({ loading: true, showDeleteUserConfirmation: false });

        return DELETE(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ loading: false, showError: true, errorMessage: DELETION_ERROR, showRemovalSuccess: false, successMessage: '' });
                else {
                    this.setState({ showError: false, loading: false, errorMessage: '', showRemovalSuccess: true, successMessage: 'Successfully removed the user ' + selectedUser.forename + ' ' + selectedUser.surname });
                    this.fetchUsers();
                }
            }).catch(() => this.setState({ showError: true, loading: false, errorMessage: DELETION_ERROR, showRemovalSuccess: false, successMessage: '' }));
    }

    render() {
        const { users, successMessage, showRemovalSuccess, loading, showUserRetrievalError, showError, errorMessage, selectedUser, showToggleUserConfirmation, showDeleteUserConfirmation, toggleMessage, deleteMessage, pageIndex, pageCount } = this.state;
        const newUserSuccessMessage = this.props.location.state === undefined ? '' : this.props.location.state.newUserSuccessMessage;
        const showRemoval = this.props.location.state === undefined ? showRemovalSuccess : this.props.location.state.showRemovalSuccess;
        const message = this.props.location.state === undefined ? successMessage : this.props.location.state.successMessage;
        const activeSelectedUser = selectedUser != null && selectedUser.active;

        return (
            loading
                ? <LoadingBar />
                : <div className="center-block mt-5">
                    <Tab title="Users" subNavList={UsersSubNav} activeKey="Users" />
                    {showUserRetrievalError
                        ? <GridErrorAlert messages="Could not retrieve users for this warehouse." />
                        : <React.Fragment>
                            <UsersFilter onSearch={this.handleOnSearch} onFilterChange={this.handleOnFilterChange} onClear={this.handleOnClear} {...this.state.filter} loading={this.state.loading} />

                            {newUserSuccessMessage && <GridSuccessAlert message={newUserSuccessMessage} />}
                            {showError && <GridErrorAlert messages={errorMessage} />}
                            {showRemoval && <GridSuccessAlert message={message} />}
                            {users.length === 0 && <NoResultsInfoAlert />}

                            <PagingTable
                                headers={UserTableHeaders}
                                rows={this.getUserRows()}
                                onNext={this.onNext}
                                onPrevious={this.onPrevious}
                                onStart={this.onStart}
                                onEnd={this.onEnd}
                                pageIndex={pageIndex}
                                pageCount={pageCount} />

                            <Confirm
                                title={activeSelectedUser ? "Confirm Deactivation" : "Confirm Activation"}
                                text={toggleMessage}
                                handleClose={this.handletoggleModalShowHide}
                                show={showToggleUserConfirmation}
                                handleConfirmAction={this.handleToggleAction}
                                buttonText={activeSelectedUser ? "Deactivate" : "Activate"}
                                variant={activeSelectedUser ? "danger" : "primary"}
                            />
                            <Confirm
                                title="Confirm Removal"
                                text={deleteMessage}
                                handleClose={this.handleDeleteModalShowHide}
                                show={showDeleteUserConfirmation}
                                handleConfirmAction={this.handleDeleteAction}
                                buttonText="Remove"
                                variant="danger"
                            />
                        </React.Fragment>
                    }
                </div>
        );
    }
}

export default UserListing;