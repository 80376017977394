import React, { Component } from 'react';
import LoadingBar from '../../common/LoadingBar';
import Breadcrumbs from '../../common/navigation/Breadcrumbs';
import { GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { OrderDetails, RunDetails, RunDetailsError } from '../Snippets';

const breadcrumbs = (orderNumber) => [
    { text: "Pallet Pick", link: "/runs/bulk/pallet" },
    { text: `Order ${orderNumber}`, link: null }
];

class BulkPalletDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            runId: props.match.params.id || null,
            run: {}
        }
    }

    componentDidMount = () =>this.getRun();

    componentDidUpdate = (prevProps) => this.props.currentlySelectedWarehouse !== prevProps.currentlySelectedWarehouse && this.props.history.push('/runs/bulk/pallet');

    getRun = () => {
        const { runId } = this.state;

        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== '' ? Endpoints.WMS_ADMIN.RUNS.GET.BULK_PALLET_DETAILS.replace("{warehouseid}", currentlySelectedWarehouse).replace("{id}", runId) : Endpoints.RUNS.GET.BULK_PALLET_DETAILS.replace("{id}", runId));

        return GET(url)
            .then(response => response.json())
            .then(result => {
                const run = result.data;
                const error = run ? false : true;
                this.setState({ run: run, error: error, loading: false });
            });
    }

    render() {
        const { run, error, loading } = this.state;

        return (
            <div className="center-block mt-5">
                <h1 className="mb-3">Run Queue</h1>
                {loading ? <LoadingBar />
                    : error ? <RunDetailsError />
                        : <React.Fragment>
                            <Breadcrumbs breadcrumbs={breadcrumbs(run.orderNumber)} />
                            <OrderDetails customerName={run.customerName} orderNumber={run.orderNumber} orderDate={run.orderDate} courier={run.courier} />
                            <RunDetails runType={run.runType} runDate={run.runCreatedDate} transitionArea={run.transitionArea} />
                        </React.Fragment>
                }
            </div>
        )
    }
}

export default BulkPalletDetails
