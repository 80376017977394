import { checkPermission } from "../../Utilities";

const OrderRunSubNav = [
  { text: "Pick", link: "/runs/order" },
  { text: "Pack", link: "/runs/order/pack" },
];

const BulkRunSubNav = [
  { text: "Shelf Pick", link: "/runs/bulk" },
  { text: "Pallet Pick", link: "/runs/bulk/pallet" },
  { text: "Pack", link: "/runs/bulk/pack" },
];

const ReplenishmentRunSubNav = [
  { text: "Replenishment", link: "/runs/replenishment" },
  { text: "PFP Replenishment", link: "/runs/replenishment/pfp" },
];

const UsersSubNav = [
  { text: "Users", link: "/company/users" },
  { text: "Add New User", link: "/company/users/new" },
];

const buildDashboardSubNav = () => {
  const InitialSubNav = [
    { text: "Queued Runs", link: "/dashboard" },
    { text: "In Progress Runs", link: "/dashboard/inprogressruns" },
  ];
  checkPermission("ViewProblems") &&
    InitialSubNav.push({ text: "Issues", link: "/dashboard/issues" });
  return InitialSubNav;
};

const IssuesSubNav = [
  { text: "Locations", link: "/issues/locations" },
  { text: "Parcels", link: "/issues/parcels" },
];

const ReturnsSubNav = [
  { text: "Returns In Process", link: "/returns/inprocess" },
  { text: "Issues", link: "/returns/issues" },
  { text: "Box Labels", link: "/returns/boxlabels" }
];

const OrderTableHeaders = [
  "Retailer Name",
  "Order Number",
  "Date Received",
  "",
  "",
];

const BulkTableHeaders = [
  "Retailer Name",
  "Order Number",
  "Date Received",
  "",
  "",
];

const ReplenishmentTableHeaders = ["Retailer Name", "Date Received", "", ""];

const UserTableHeaders = ["Name", "Email Address", "Last Login", "", "", ""];

const FulfilmentLabelTableHeaders = ["Consignment", "Date Received", "", ""];

const CollectionManifestTableHeaders = [
  "Courier",
  "Date Received",
  "Print Status",
  "Printed By",
  "",
];

const ReturnTableHeaders = ["Return Reference", "Product", "Date Created", "Print Status", ""];

const ReturnIssueTableHeaders = [ "Return Reference", "Order No.", "Time Reported", "" ];

const ReturnBoxLabelTableHeaders = [ "Returned Product", "SKU", "Stock Box Label" ];

const IssueParcelTableHeaders = [
  "Order Number",
  "Missing Items",
  "Picker",
  "Order Created",
];

const IssueLocationTableHeaders = [
  "Location",
  "Product Missing",
  "Picker",
  "Time Flagged",
];



const StrongPasswordPattern =
  /^(?=.*[a-z])(?=.*[0-9])(?=.*[#$^+=!*()@%&"'[\]{},_\\/;:`\-~<>]).{6,}/;
const NumberPattern = /^(?=.*[0-9])/;
const SpecialCharPattern = /^(?=.*[#$^+=!*()@%&"'[\]{},_\\/;:`\-~<>])/;
const LengthPattern = /^.{6,}/;

/**
 * 30 seconds
 */
const DASHBOARD_REFRESH_INTERVAL = 30000;

export {
  OrderRunSubNav,
  BulkRunSubNav,
  ReplenishmentRunSubNav,
  IssuesSubNav,
  ReturnsSubNav,
  OrderTableHeaders,
  BulkTableHeaders,
  ReplenishmentTableHeaders,
  UserTableHeaders,
  FulfilmentLabelTableHeaders,
  CollectionManifestTableHeaders,
  ReturnTableHeaders,
  IssueParcelTableHeaders,
  IssueLocationTableHeaders,
  ReturnIssueTableHeaders,
  ReturnBoxLabelTableHeaders,
  UsersSubNav,
  StrongPasswordPattern,
  NumberPattern,
  SpecialCharPattern,
  LengthPattern,
  DASHBOARD_REFRESH_INTERVAL,
  buildDashboardSubNav,
};
