import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import Title from "../common/Title";
import { DashboardTileCustomImage } from "./Snippets";
import { GET } from "../../Consumer";
import Endpoints from "../common/Endpoints";
import LoadingBar from "../common/LoadingBar";
import { ErrorAlert } from "../common/Alerts";
import Tab from "../common/navigation/Tab";
import {
  buildDashboardSubNav,
  DASHBOARD_REFRESH_INTERVAL,
} from "../common/Constants";
import { useInterval } from "../../Utilities";
import "../dashboard/Dashboard.scss";

const InProgressRuns = ({ currentlySelectedWarehouse }) => {
  //#region State
  const [loading, setLoading] = useState(true);
  const [dashboardDetails, setDashboardDetails] = useState(null);
  const [dashboardSubNav, setDashboardSubNav] = useState([]);

  //#endregion

  //#region API

  /**
   * Fetch data
   */
  const fetchData = useCallback(
    (handleLoading = false) => {
      (async () => {
        setLoading(handleLoading);
        try {
          const url = currentlySelectedWarehouse
            ? Endpoints.WMS_ADMIN.WAREHOUSE.GET.IN_PROGRESS_COUNTS +
              "/" +
              currentlySelectedWarehouse
            : Endpoints.WMS_ADMIN.WAREHOUSE.GET.IN_PROGRESS_COUNTS;
          const response = await GET(url);
          if (response?.ok) {
            const { data } = await response.json();
            setDashboardDetails(data);
          }
        } catch (error) {
          console.log({ error });
        } finally {
          setLoading(false);
        }
      })();
    },
    [currentlySelectedWarehouse]
  );

  // Run on first page load.
  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  // Re-run data fetching on set interval.
  useInterval(fetchData, DASHBOARD_REFRESH_INTERVAL);

  useEffect(() => {
    if (dashboardDetails) {
      setDashboardSubNav(buildDashboardSubNav());
    }
  }, [dashboardDetails]);

  //#endregion

  return (
    <>
      {loading ? (
        <LoadingBar />
      ) : (
        <div className="center-block mt-5">
          <Title title="Dashboard" />
          <Tab subNavList={dashboardSubNav} activeKey="In Progress Runs" />
          {dashboardDetails === null ? (
            <Row>
              <Col sm={12} md={6}>
                <ErrorAlert messages="Could not retrieve dashboard information for this warehouse." />
              </Col>
            </Row>
          ) : (
            <Row>
              <DashboardTileCustomImage
                title="Pick In Progress"
                iconURL="Images/inprogressruns/pickinprogress.svg"
                iconClass="dashboard"
                count={dashboardDetails.pickerRunInProgress.count}
              />
              <DashboardTileCustomImage
                title="Pack In Progress"
                iconURL="Images/inprogressruns/packinprogress.svg"
                iconClass="dashboard"
                count={dashboardDetails.packerRunInProgress.count}
              />
              <DashboardTileCustomImage
                title="Bulk Shelf Pick In Progress"
                iconURL="Images/inprogressruns/bulkshelfpickinprogress.svg"
                iconClass="dashboard"
                count={dashboardDetails.bulkShelfPickRunInProgress.count}
              />
              <DashboardTileCustomImage
                title="Bulk Pallet Pick In Progress"
                iconURL="Images/inprogressruns/bulkpalletpickinprogress.svg"
                iconClass="dashboard"
                count={dashboardDetails.bulkPalletPickRunInProgress.count}
              />
              <DashboardTileCustomImage
                title="Bulk Pack In Progress"
                iconURL="Images/inprogressruns/bulkpackinprogress.svg"
                iconClass="dashboard"
                count={dashboardDetails.bulkPackRunInProgress.count}
              />
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default InProgressRuns;
