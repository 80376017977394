import { Row, Col } from 'react-bootstrap';
import { InfoAlert } from './Alerts';

const WarehouseVerificationMessage = () => (
    <Row>
        <Col sm={12} md={6} >
            <InfoAlert heading="Select Warehouse" message="A warehouse needs to be selected for relevant information to be displayed." />
        </Col>
    </Row>
);

export default WarehouseVerificationMessage;