import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const WarningAlert = ({ heading, message }) =>
    <Card className="card-warning mb-3">
        {heading && <Card.Title>{heading}</Card.Title>}
        <Card.Body>{message}</Card.Body>
    </Card>

const ErrorAlert = ({ heading, messages }) =>
    <Card className="card-danger mb-3">
        {heading && <Card.Title>{heading}</Card.Title>}
        <Card.Body>
            {typeof messages === 'string'
                ? <React.Fragment>{messages}</React.Fragment>
                : <React.Fragment>{messages.map((e, i) => <p key={i}>{e}</p>)}</React.Fragment>
            }
        </Card.Body>
    </Card>

const InfoAlert = ({ heading, message }) =>
    <Card className="card-info mb-3">
        {heading && <Card.Title>{heading}</Card.Title>}
        <Card.Body>{message}</Card.Body>
    </Card>

const SuccessAlert = ({ heading, message }) =>
    <Card className="card-success mb-3">
        {heading && <Card.Title>{heading}</Card.Title>}
        <Card.Body>{message}</Card.Body>
    </Card>

const GridErrorAlert = ({ heading, message }) => <Row><Col sm={12} md={6}><ErrorAlert heading={heading} messages={message} /></Col></Row>

const GridInfoAlert = ({ heading, message }) => <Row><Col sm={12} md={6}><InfoAlert heading={heading} message={message} /></Col></Row>

const GridSuccessAlert = ({ heading, message }) => <Row><Col sm={12} md={6}><SuccessAlert heading={heading} message={message} /></Col></Row>

const NoResultsInfoAlert = () =>
    <Row>
        <Col sm={12} md={6}>
            <InfoAlert message="There are no results available. If you have entered details please check that they are correct and try again." />
        </Col>
    </Row>

export {
    WarningAlert,
    ErrorAlert,
    InfoAlert,
    SuccessAlert,
    GridErrorAlert,
    GridInfoAlert,
    GridSuccessAlert,
    NoResultsInfoAlert
}