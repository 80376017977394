import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Tab from "../common/navigation/Tab";
import { IssuesSubNav, DASHBOARD_REFRESH_INTERVAL } from "../common/Constants";
import Confirm from "../common/modals/Confirm";
import GenericModal from "../common/modals/GenericModal";
import LoadingBar from "../common/LoadingBar";
import { Row, Col, Button, Form } from "react-bootstrap";
import { ErrorAlert } from "../../components/common/Alerts";
import Endpoints from "../common/Endpoints";
import { GET, PUT } from "../../Consumer";
import { useParams, useHistory } from "react-router-dom";
import { useInterval, checkPermission } from "../../Utilities";
import { useFormValidator } from "@selazar-ui/core";

const CANNOT_RETRIEVE_INFO_ERROR =
  "Could not retrieve location issue information for this warehouse.";

const CANNOT_UPDATE_QUANTITY_ERROR =
  "An error occured whilst attempting to update the quantity. Please refresh the page to try again.";

const FEATURE_NOT_ENABLED_ERROR =
  "This feature is not currently enabled, please contact support.";

const LocationDetails = ({ currentlySelectedWarehouse }) => {
  //#region State
  const [loading, setLoading] = useState(true);
  const [featureFlagRequestLoading, setFeatureFlagRequestLoading] =
    useState(true);
  const [fetchDataRequestLoading, setFetchDataRequestLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useParams();
  const { push } = useHistory();
  const [data, setData] = useState(null);
  const [showStockNotFoundModal, setShowStockNotFoundModal] = useState(false);
  const [showConfirmStockQuantityModal, setShowConfirmStockQuantityModal] =
    useState(false);
  const [showUpdateStockQuantityModal, setShowUpdateStockQuantityModal] =
    useState(false);
  const [updatedQuantity, setUpdatedQuantity] = useState("");
  const [issuesFeatureEnabled, setIssuesFeatureEnabled] = useState(false);
  const [showButtons] = useState(checkPermission("ResolveProblems"));

  useEffect(() => {
    setLoading(featureFlagRequestLoading || fetchDataRequestLoading);
  }, [featureFlagRequestLoading, fetchDataRequestLoading]);

  //#endregion

  //#region API

  /**
   * Feature flag
   */
  useEffect(() => {
    (async () => {
      setFeatureFlagRequestLoading(true);
      try {
        const url = new URL(Endpoints.FULFILMENT_CONFIGURATION.GET.SEARCH);

        url.searchParams.append("query", "EnableFlagMissingItemFeature");

        const response = await GET(url);

        if (response?.ok) {
          const responseData = await response.json();
          const enableFeature = responseData.data.value === 1 ? true : false;
          setIssuesFeatureEnabled(enableFeature);
          enableFeature
            ? setErrorMessage("")
            : setErrorMessage(FEATURE_NOT_ENABLED_ERROR);
        } else {
          setErrorMessage(FEATURE_NOT_ENABLED_ERROR);
        }
      } catch (error) {
        console.log({ error });
        setErrorMessage(CANNOT_RETRIEVE_INFO_ERROR);
      }
      setFeatureFlagRequestLoading(false);
    })();
  }, []);

  /**
   * Fetch data
   */
  const fetchData = useCallback(
    (handleLoading = false) => {
      if (issuesFeatureEnabled)
        (async () => {
          setFetchDataRequestLoading(handleLoading);
          try {
            const response = await GET(
              currentlySelectedWarehouse != null
                ? `${Endpoints.WMS_ADMIN.PROBLEMS.GET.PROBLEM_LOCATION.replace(
                    "{id}",
                    id
                  )}/${currentlySelectedWarehouse}`
                : Endpoints.WMS_ADMIN.PROBLEMS.GET.PROBLEM_LOCATION.replace(
                    "{id}",
                    id
                  )
            );

            if (response?.ok) {
              const { data } = await response.json();
              setData(data);
              setErrorMessage("");
            } else {
              setErrorMessage(CANNOT_RETRIEVE_INFO_ERROR);
            }
          } catch (error) {
            console.log({ error });
            setErrorMessage(CANNOT_RETRIEVE_INFO_ERROR);
          } finally {
            setFetchDataRequestLoading(false);
          }
        })();
    },
    [id, currentlySelectedWarehouse, issuesFeatureEnabled]
  );

  // Run on first page load.
  useEffect(() => {
    fetchData(true);
  }, [fetchData, issuesFeatureEnabled]);

  // Re-run data fetching on set interval.
  useInterval(fetchData, DASHBOARD_REFRESH_INTERVAL);

  /**
   * Update stock quantity
   */
  const updateStockQuantity = useCallback(
    async (quantity) => {
      setLoading(true);
      try {
        const url = new URL(
          currentlySelectedWarehouse
            ? `${Endpoints.WMS_ADMIN.PROBLEMS.PUT.RESOLVE_PROBLEM_LOCATION.replace(
                "{id}",
                id
              )}/${currentlySelectedWarehouse}`
            : Endpoints.WMS_ADMIN.PROBLEMS.PUT.RESOLVE_PROBLEM_LOCATION.replace(
                "{id}",
                id
              )
        );

        const response = await PUT(url, { quantity });

        if (response?.ok) {
          push("/issues/locations");
          setErrorMessage("");
        } else throw new Error();
      } catch (error) {
        console.log({ error });
        setErrorMessage(CANNOT_UPDATE_QUANTITY_ERROR);
      } finally {
        setLoading(false);
      }
    },
    [currentlySelectedWarehouse, id, push]
  );

  //#endregion

  //#region Controls

  const handleConfirmStockQuantityClose = () => {
    setShowConfirmStockQuantityModal(false);
    setShowUpdateStockQuantityModal(false);
  };

  const handleConfirmStockQuantityModalSecondaryAction = () => {
    !showUpdateStockQuantityModal
      ? setShowUpdateStockQuantityModal(true)
      : handleConfirmStockQuantityClose();
  };

  const handleConfirmStockQuantityModalPrimaryAction = () => {
    !showUpdateStockQuantityModal
      ? handleQuantityIsCorrect()
      : handleUpdateStockQuantity();
  };

  const handleStockNotFound = async () => {
    setShowStockNotFoundModal(false);
    await updateStockQuantity(0);
  };

  const handleQuantityIsCorrect = async () => {
    handleConfirmStockQuantityClose();
    await updateStockQuantity(data.expectedQuantity);
  };

  const handleUpdateStockQuantity = async () => {
    var validation = validate({ updatedQuantity: updatedQuantity });

    if (validation?.isValid) {
      handleConfirmStockQuantityClose();
      await updateStockQuantity(updatedQuantity);
    }
  };

  const { validate, validationResults } = useFormValidator([
    {
      field: "updatedQuantity",
      method: "isInt",
      args: [{ min: 0, max: 2147483647 }],
      validWhen: true,
      message: "Quantity must be 0 or above",
    },
  ]);

  //#endregion

  //#region Render
  if (loading) return <LoadingBar />;

  return (
    <div className="center-block mt-5">
      <Tab title="Issues" subNavList={IssuesSubNav} activeKey="Locations" />
      <Link to="/issues/locations">
        <FontAwesomeIcon className="mr-2" icon={faChevronLeft} />
        Return to issues
      </Link>
      {errorMessage !== "" ? (
        <Row>
          <Col sm={12} md={6}>
            <ErrorAlert messages={errorMessage} />
          </Col>
        </Row>
      ) : (
        data && (
          <section className="section-first">
            <h2>Issue Location Overview</h2>

            <p className="section-title">Retailer Name</p>
            <p>{data.retailerName}</p>

            <p className="section-title mt-4">Location</p>
            <p>{data.location}</p>

            <p className="section-title mt-4">Missing Product</p>
            <p>
              {data.missingProduct}
              {data.skuCode && " - " + data.skuCode}
              {data.selazarUniqueID && " - " + data.selazarUniqueID}
              {data.ean && " - " + data.ean}
            </p>

            <p className="section-title mt-4">Quantity at Location</p>
            <p>{data.expectedQuantity}</p>

            <p className="section-title mt-4">Picker</p>
            <p>{data.pickerEmail}</p>

            {showButtons && (
              <div className="mt-3">
                <Button
                  className="mr-4"
                  onClick={() => setShowConfirmStockQuantityModal(true)}
                >
                  Update Quantity
                </Button>

                <Button
                  variant="outline-primary"
                  onClick={() => setShowStockNotFoundModal(true)}
                >
                  Stock Not Found
                </Button>
              </div>
            )}

            <GenericModal
              title={
                !showUpdateStockQuantityModal
                  ? "Confirm Stock Quantity"
                  : "Update Stock Quantity"
              }
              handleClose={handleConfirmStockQuantityClose}
              show={showConfirmStockQuantityModal}
              secondaryAction={handleConfirmStockQuantityModalSecondaryAction}
              secondaryActionText={
                !showUpdateStockQuantityModal ? "No" : "Cancel"
              }
              primaryAction={handleConfirmStockQuantityModalPrimaryAction}
              primaryActionText={
                !showUpdateStockQuantityModal
                  ? "Quantity is correct"
                  : "Confirm"
              }
            >
              {!showUpdateStockQuantityModal ? (
                <p>Is the stock quantity at this location correct?</p>
              ) : (
                <Form>
                  <Row>
                    <Col>
                      <h6 style={{ fontWeight: "bold" }}>Item</h6>
                    </Col>
                    <Col>
                      <h6 style={{ fontWeight: "bold" }}>Quantity</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{data.missingProduct}</p>
                    </Col>
                    <Col>
                      <p>{data.expectedQuantity}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>Updated Stock Level</p>
                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        placeholder="Quantity"
                        value={updatedQuantity}
                        onChange={(e) => setUpdatedQuantity(e.target.value)}
                        onKeyDown={(evt) =>
                          (evt.key === "e" ||
                            evt.key === "+" ||
                            evt.key === "-" ||
                            evt.key === "." ||
                            evt.key === "E") &&
                          evt.preventDefault()
                        }
                      />
                      <span className="text-danger small">
                        {validationResults?.updatedQuantity?.message}
                      </span>
                    </Col>
                  </Row>
                </Form>
              )}
            </GenericModal>
            <Confirm
              show={showStockNotFoundModal}
              handleClose={() => setShowStockNotFoundModal(false)}
              buttonText="Yes"
              handleConfirmAction={handleStockNotFound}
              title="Stock Not Available"
              text="Are you sure you want to mark this stock as not found?"
            />
          </section>
        )
      )}
    </div>
  );

  //#endregion
};

export default LocationDetails;
