import React from "react";
import { Modal, Button } from "react-bootstrap";

import "./Confirm.scss";

const GenericModal = ({
  title,
  handleClose,
  show,
  secondaryAction,
  secondaryActionText = "Cancel",
  primaryAction,
  primaryActionText,
  children,
}) => (
  <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer className="pt-0 mb-2">
      <Button
        variant="link"
        onClick={secondaryAction}
        style={{ minWidth: 100 }}
      >
        {secondaryActionText}
      </Button>
      <Button onClick={primaryAction} style={{ minWidth: 100 }}>
        {primaryActionText}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default GenericModal;
