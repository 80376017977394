import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button } from 'react-bootstrap';
import CardPage from '../../common/CardPage';
import { WarningAlert } from '../../common/Alerts';

class Expired extends Component {

    handleReturnLogin = () => this.props.history.push({ pathname: "/" });

    handleForgotPassword = () => this.props.history.push({ pathname: "/account/password/forgot" });

    renderExpiredContent = () => (
        <React.Fragment>
            <h3>Reset Password</h3>
            <WarningAlert message="The password reset link has expired. Send a new password reset email." />
            <p>Your password reset link is only valid for 2 hours. Resend a reset password email. If you need further help contact support@selazar.com</p>
            <div className="d-block text-center m-5">
                <img src="Images/management.svg" alt="" height="150" />
            </div>
            <Button variant="secondary" block type="submit" value="Submit" className="mb-4" onClick={this.handleForgotPassword}>Resend password reset email</Button>
            <p className="font-weight-bold mb-0">Have your reset your password?</p>
            <Button variant="primary" block type="submit" value="Submit" onClick={this.handleReturnLogin}>Login</Button>
        </React.Fragment>
    );

    render() {
        return (
                <CardPage content={this.renderExpiredContent()} />
        );
    }
}

export default withRouter(Expired);