import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import Title from "../common/Title";
import {
  faDollyFlatbed,
  faTruck,
  faPeopleCarry,
  faArchive,
  faBoxes,
  faBoxOpen,
  faPallet,
} from "@fortawesome/free-solid-svg-icons";
import { DashboardTile } from "./Snippets";
import { GET } from "../../Consumer";
import Endpoints from "../common/Endpoints";
import LoadingBar from "../common/LoadingBar";
import { ErrorAlert } from "../common/Alerts";
import Tab from "../common/navigation/Tab";
import {
  buildDashboardSubNav,
  DASHBOARD_REFRESH_INTERVAL,
} from "../common/Constants";
import { useInterval } from "../../Utilities";
import "../dashboard/Dashboard.scss";

const getThresholdClass = (threshold) =>
  threshold === "High"
    ? "card-threshold-high"
    : threshold === "Medium"
    ? "card-threshold-medium"
    : threshold === "Low"
    ? "card-threshold-low"
    : "";

const QueuedRuns = ({ currentlySelectedWarehouse }) => {
  //#region State
  const [loading, setLoading] = useState(true);
  const [dashboardDetails, setDashboardDetails] = useState(null);
  const [dashboardSubNav, setDashboardSubNav] = useState([]);

  //#endregion

  //#region API

  /**
   * Fetch data
   */
  const fetchData = useCallback(
    (handleLoading = false) => {
      (async () => {
        setLoading(handleLoading);

        try {
          const url = currentlySelectedWarehouse
            ? Endpoints.WMS_ADMIN.WAREHOUSE.GET.PENDING_COUNTS +
              "/" +
              currentlySelectedWarehouse
            : Endpoints.WMS_ADMIN.WAREHOUSE.GET.PENDING_COUNTS;

          const response = await GET(url);
          if (response?.ok) {
            const { data } = await response.json();
            setDashboardDetails(data);
          }
        } catch (error) {
          console.log({ error });
        } finally {
          setLoading(false);
        }
      })();
    },
    [currentlySelectedWarehouse]
  );

  // Run on first page load.
  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  // Re-run data fetching on set interval.
  useInterval(fetchData, DASHBOARD_REFRESH_INTERVAL);

  useEffect(() => {
    if (dashboardDetails) {
      setDashboardSubNav(buildDashboardSubNav());
    }
  }, [dashboardDetails]);

  //#endregion

  return (
    <>
      {loading ? (
        <LoadingBar />
      ) : (
        <div className="center-block mt-5">
          <Title title="Dashboard" />
          <Tab subNavList={dashboardSubNav} activeKey="Queued Runs" />
          {dashboardDetails === null ? (
            <Row>
              <Col sm={12} md={6}>
                <ErrorAlert messages="Could not retrieve dashboard information for this warehouse." />
              </Col>
            </Row>
          ) : (
            <Row>
              <DashboardTile
                icon={faDollyFlatbed}
                title="Pick"
                count={dashboardDetails.pickerRunPending.count}
                link="/runs/order"
                iconClass={getThresholdClass(
                  dashboardDetails.pickerRunPending.state
                )}
              />
              <DashboardTile
                icon={faTruck}
                title="Pack"
                count={dashboardDetails.packerRunPending.count}
                link="/runs/order/pack"
                iconClass={getThresholdClass(
                  dashboardDetails.packerRunPending.state
                )}
              />
              <DashboardTile
                icon={faPeopleCarry}
                title="Replenishment"
                count={dashboardDetails.stockReplenishmentRun.count}
                link="/runs/replenishment"
                iconClass={getThresholdClass(
                  dashboardDetails.stockReplenishmentRun.state
                )}
              />
              <DashboardTile
                icon={faArchive}
                title="Bulk Shelf Pick"
                count={dashboardDetails.bulkShelfPickRunPending.count}
                link="/runs/bulk"
                iconClass={getThresholdClass(
                  dashboardDetails.bulkShelfPickRunPending.state
                )}
              />
              <DashboardTile
                icon={faBoxes}
                title="Bulk Pallet Pick"
                count={dashboardDetails.bulkPalletPickRunPending.count}
                link="/runs/bulk/pallet"
                iconClass={getThresholdClass(
                  dashboardDetails.bulkPalletPickRunPending.state
                )}
              />
              <DashboardTile
                icon={faBoxOpen}
                title="Bulk Pack"
                count={dashboardDetails.bulkPackRunPending.count}
                link="/runs/bulk/pack"
                iconClass={getThresholdClass(
                  dashboardDetails.bulkPackRunPending.state
                )}
              />
              <DashboardTile
                icon={faPallet}
                title="PFP Replenishment"
                count={dashboardDetails.pfpStockReplenishmentRun.count}
                link="/runs/replenishment/pfp"
                iconClass={getThresholdClass(
                  dashboardDetails.pfpStockReplenishmentRun.state
                )}
              />
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default QueuedRuns;
