import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ReCAPTCHA from "react-google-recaptcha";
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import FormValidator from '../../common/FormValidator';
import CardPage from '../../common/CardPage';
import { GET, PUT, extractData } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert, WarningAlert } from '../../common/Alerts';

const STRONG_PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[@$!%*#?&]).{6,}/;
const NUMBER_PATTERN = /^(?=.*[0-9])/;
const SPECIAL_CHAR_PATTERN = /^(?=.*[@$!%*#?&])/;
const LENGTH_PATTERN = /^.{6,}/;

const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_TOKEN;

class Reset extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'password',
                method: 'matches',
                args: [STRONG_PASSWORD_PATTERN],
                validWhen: true,
                message: 'The password does not meet the criteria. Check your password and try again.'
            },
            {
                field: 'password',
                 method: 'isLength',
                 args: [{min: 6, max: 50}],
                 validWhen: true,
                 message: 'Password must be between 6 and 50 characters long'
            }
        ]);

        this.state = {
            form: {
                operatorUserID: '',
                userID: '',
                password: '',
                confirmPassword: ''
            },
            passwordresetid: props.match.params.passwordresetid || null,
            passwordResetRequest: '',
            errorMessages: [],
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            passwordChecks: {
                passwordNumberCheck: false,
                passwordSpecialCharCheck: false,
                passwordLengthCheck: false
            },
            validation: this.validator.valid(),
            recaptchaError: false
        }

    }

    componentDidMount = () => this.getPasswordResetRequest();

    getPasswordResetRequest = async () => {
        const { passwordresetid } = this.state;

        const response = await GET(Endpoints.PASSWORD.GET.BY_ID + passwordresetid);
        const result = await response.json();
        if (result.error)
            this.props.history.push('/account/password/expired');
        else
            this.setState({ error: false, passwordResetRequest: extractData(result) });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { form, recaptcha } = this.state;

        const validation = this.validator.validate(form);
        this.setState({ validation: validation, recaptchaError: !recaptcha });

        this.submitted = true;

        // TODO: Remove when Confirm Password is stripped from the entire application. 
        form.confirmPassword = form.password;

        if (recaptcha && validation.isValid) {
            try {
                const response = await PUT(Endpoints.PASSWORD.PUT.CHANGE_PASSWORD, form);
                const result = await response.json();
                if (result.error) this.setState({ errorMessages: ["There was an issue with updating your new password. If you need further help, contact support@selazar.com"] });
                else this.props.history.push({ pathname: "/account/password/complete" });
            } catch (e) {
                this.setState({ errorMessages: ["There was an issue with updating your new password. If you need further help, contact support@selazar.com"] });
            }
        }
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "password") {
            this.setState({
                passwordChecks: {
                    passwordNumberCheck: NUMBER_PATTERN.test(value),
                    passwordSpecialCharCheck: SPECIAL_CHAR_PATTERN.test(value),
                    passwordLengthCheck: LENGTH_PATTERN.test(value)
                },
                form: {
                    [name]: value
                }
            });
        }
    }

    handleRecaptcha = () => this.setState({ recaptcha: true });

    validate = (form) => this.validator.validate(form)

    renderResetContent = () => {
        const { form, errorMessages, recaptchaError, passwordResetRequest, passwordInputType, passwordInputIcon, validation, passwordChecks } = this.state;
        form.userID = passwordResetRequest.userID

        return (<React.Fragment>
            <h3>Create Password</h3>
            <Form onSubmit={this.handleSubmit}>
                <input id="hfUserID" type="hidden" name="userID" value={passwordResetRequest.userID} />
                <FormGroup>
                    {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                    {validation.password.message.length > 0 && <WarningAlert message={validation.password.message} />}
                    <FormLabel htmlFor="passwordField" className="font-weight-bold">New Password</FormLabel>
                    <FormControl id="passwordField" maxLength="50" type={passwordInputType} name="password" value={form.password} onChange={this.handleInputChange} />
                    <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
                </FormGroup>
                <div className="form-password-checklist">
                    <ul>
                        <li className={this.submitted && !passwordChecks.passwordLengthCheck ? "submission-fail" : passwordChecks.passwordLengthCheck ? "pass" : "fail"}>Six characters long</li>
                        <li className={this.submitted && !passwordChecks.passwordNumberCheck ? "submission-fail" : passwordChecks.passwordNumberCheck ? "pass" : "fail"}>One number</li>
                        <li className={this.submitted && !passwordChecks.passwordSpecialCharCheck ? "submission-fail" : passwordChecks.passwordSpecialCharCheck ? "pass" : "fail"}>One special character</li>
                    </ul>
                </div>
                <div className="center">
                    <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={this.handleRecaptcha} />
                </div>
                {recaptchaError && <p className="center text-danger">Please complete captcha</p>}
                <Button variant="primary" block type="submit" value="Submit">Save new password</Button>
            </Form>
        </React.Fragment>);
    }

    render() {
        return (
            <CardPage content={this.renderResetContent()} />
        );
    }
}

export default withRouter(Reset);