import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { POST, extractData } from '../../Consumer';
import FormValidator from '../common/FormValidator';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ErrorAlert, WarningAlert } from '../common/Alerts';
import Endpoints from '../common/Endpoints';
import CardPage from '../common/CardPage';
import LoadingBar from '../common/LoadingBar';

const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_TOKEN;
const SHOW_CAPTCHA = process.env.REACT_APP_SHOW_CAPTCHA === 'true';
const TOKEN_EXPIRED = { "HEADING": "Session Expired", "MESSAGE": "Your session has timed out. Please login." }

class Login extends Component {

  validator = new FormValidator([
    {
      field: 'email',
      method: 'isEmail',
      validWhen: true,
      message: 'Your email address is required.'
    },
    {
      field: 'password',
      method: 'isEmpty',
      validWhen: false,
      message: 'Your password is required.'
    }
  ]);

  state = {
    form: {
      email: '',
      password: ''
    },
    validation: this.validator.valid(),
    errorMessages: [],
    passwordInputType: 'password',
    passwordInputIcon: faEye,
    loading: false,
    recaptchaError: false,
    tokenExpired: this.props.tokenExpired
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { form, recaptcha } = this.state;
    const validation = this.validate(form);
    this.setState({ validation: validation, loading: true, recaptchaError: SHOW_CAPTCHA ? !recaptcha : false });

    if (SHOW_CAPTCHA ? recaptcha && validation.isValid : validation.isValid) {
      try {
        const res = await POST(Endpoints.USER.POST.LOGIN, form);
        const result = await res.json();
        if (result.error) result.data !== null ? this.setState({ errorMessages: result.data, loading: false }) : this.setState({ errorMessages: result.message, loading: false });
        else {
          this.props.onLogin(extractData(result), false);
          this.props.history.push({ pathname: "/dashboard" });
        }
      } catch (e) {
        return this.setState({ errorMessages: ["There has been an issue logging in."], loading: false });
      }
    } else this.setState({ loading: false });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        ...{ [name]: value }
      }
    }));
  }

  handleShowHidePassword = () => {
    const { passwordInputType } = this.state;

    passwordInputType === "password" ?
      this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
      this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
  }

  handleRecaptcha = () => this.setState({ recaptcha: true });

  validate = (form) => this.validator.validate(form)

  renderLoginContent = () => {
    const { form, validation, passwordInputIcon, passwordInputType, errorMessages, recaptchaError, tokenExpired: tokenExpired } = this.state;
    return (<React.Fragment>
      {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
      {tokenExpired && <WarningAlert heading={TOKEN_EXPIRED.HEADING} message={TOKEN_EXPIRED.MESSAGE} />}
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <FormLabel htmlFor="emailField" className="font-weight-bold">Email</FormLabel>
          <FormControl id="emailField" type="email" value={form.email} name="email" maxLength="50" className={validation.email.isInvalid ? "input-error" : undefined} onChange={this.handleInputChange} autoFocus />
          <span className="text-danger small">{validation.email.message}</span>
        </FormGroup>
        <FormGroup className="mb-1">
          <FormLabel htmlFor="passwordField" className="font-weight-bold">Password</FormLabel>
          <FormControl id="passwordField" type={passwordInputType} value={form.password} name="password" maxLength="50" className={validation.password.isInvalid ? "input-error" : undefined} onChange={this.handleInputChange} />
          <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
          <span className="text-danger small">{validation.password.message}</span>
        </FormGroup>
        <FormGroup className="mb-4">
          <Link to="/account/password/forgot" className="link-button">Forgot Password?</Link>
        </FormGroup>
        {SHOW_CAPTCHA && (
          <div className="center">
            <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={this.handleRecaptcha} />
          </div>
        )}
        {recaptchaError && <p className="center text-danger">Please complete captcha</p>}
        <FormGroup>
          <Button variant="primary" type="submit" value="Submit" block>Login</Button>
        </FormGroup>
      </Form>
    </React.Fragment>);
  }

  render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        {loading && <LoadingBar />}
        <CardPage content={this.renderLoginContent()} showTitle="true" />
      </React.Fragment>
    );
  }
}

export default withRouter(Login);