import React, { Component } from 'react';
import { GET, extractData } from '../../Consumer';
import { toLocalTimeString, goToURL } from '../../Utilities';
import { Row, Col, Button } from 'react-bootstrap';
import LoadingBar from '../common/LoadingBar';
import Endpoints from '../common/Endpoints';
import { InfoAlert, ErrorAlert } from '../common/Alerts';

const LabelDetails = ({ supplierName, createdDate }) =>
    <section className="mt-3">
        <h3>Label Details</h3>
        <p className="section-title">Supplier Name</p>
        <p>{supplierName}</p>
        <p className="section-title">Date Created</p>
        <p>{toLocalTimeString(createdDate)}</p>
    </section>

const DownloadLabels = ({ boxLabelsUrl, itemLabelsUrl }) =>
    <section>
        <h3>Labels</h3>
        <p className="section-title">Stock Box Labels</p>
        <div className="download-action-container">
            <Button variant="secondary mb-3" onClick={() => { goToURL(boxLabelsUrl) }}>Download Stock Box Label(s)</Button>
            {itemLabelsUrl &&
                <React.Fragment>
                    <p className="section-title">Product Labels</p>
                    <Button variant="secondary" onClick={() => { goToURL(itemLabelsUrl) }}>Download Product Label(s)</Button>
                </React.Fragment>
            }
        </div>
    </section>

class InboundStock extends Component {

    state = {
        stockConsignmentLabel: null,
        loading: true,
        showError: false
    };

    async componentDidMount() {
        await this.fetchLabels();
        setInterval(this.fetchLabels, 30000); // runs every 30 seconds.
    }

    fetchLabels = () => {
        const { currentlySelectedWarehouse } = this.props;

        return GET(new URL(currentlySelectedWarehouse !== '' ? Endpoints.FULFILMENT.GET.INBOUND_STOCK_LABELS.replace("{warehouseid}", currentlySelectedWarehouse) : Endpoints.FULFILMENT.GET.INBOUND_STOCK_LABELS))
            .catch(error => console.log(error))
            .then(response => { if (response.ok) return response.json(); })
            .then(result => {
                if (result.error) this.setState({ stockConsignmentLabel: null, showError: true, loading: false });
                else this.setState({ stockConsignmentLabel: extractData(result), loading: false });
            }).catch(() => this.setState({ loading: false, showError: true }));
    }

    render() {
        const { stockConsignmentLabel, loading, showError } = this.state;

        return (
            loading
                ? <LoadingBar /> :
                <div className="center-block mt-5">
                    <h1>Inbound Stock</h1>
                    <Row>
                        <Col sm={12} md={6}>
                            {showError
                                ? <ErrorAlert messages="Could not retrieve inbound stock labels for this warehouse." />
                                : stockConsignmentLabel
                                    ? <React.Fragment>
                                        <LabelDetails supplierName={stockConsignmentLabel.supplierName} createdDate={stockConsignmentLabel.createdDate} />
                                        <DownloadLabels boxLabelsUrl={stockConsignmentLabel.boxLabelsUrl} itemLabelsUrl={stockConsignmentLabel.itemLabelsUrl} />
                                    </React.Fragment>
                                    : <InfoAlert message="There are no inbound stock labels at this time." />
                            }
                        </Col>
                    </Row>
                </div>
        )
    }
}

export default InboundStock;