import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import FormValidator from '../../common/FormValidator';
import { ErrorAlert } from '../../common/Alerts';
import CardPage from '../../common/CardPage';
import { PUT } from '../../../Consumer'
import Endpoints from '../../common/Endpoints';

const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_TOKEN;

class Forgot extends Component {

    validator = new FormValidator([
        {
            field: 'email',
            method: 'isEmail',
            validWhen: true,
            message: 'Your email address is required.'
        }
    ]);

    state = {
        form: {
            email: '',
        },
        errorMessages: [],
        validation: this.validator.valid(),
        recaptchaError: false
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { form, recaptcha } = this.state;

        const validation = this.validator.validate(form);
        this.setState({ validation: validation, recaptchaError: !recaptcha });

        if (recaptcha && validation.isValid) {
            try {
                const response = await PUT(Endpoints.PASSWORD.PUT.RESET_PASSWORD + form.email);
                const result = await response.json();
                if (result.error)
                    this.setState({ errorMessages: ["There was an issue with resetting your password. If you need further help, contact support@selazar.com"] });
                else
                    this.props.history.push({ pathname: "/account/password/sent", state: { email: form.email } });
            } catch (e) {
                return this.setState({ errorMessages: ["There has been an issue submitting the password."] });
            }
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleRecaptcha = () => this.setState({ recaptcha: true });

    validate = (form) => this.validator.validate(form)

    renderForgotContent = () => {
        const { form, validation, errorMessages, recaptchaError } = this.state;

        return (<React.Fragment>
            <h3>Reset Password</h3>
            {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
            <p>Enter your e-mail address and we will send you an email with your information on how to reset your password.</p>
            <Form>
                <FormGroup>
                    <FormLabel htmlFor="verificationCodeField">Email</FormLabel>
                    <FormControl type="text" className={validation.email.isInvalid ? "input-error" : undefined} value={form.email} name="email" onChange={this.handleInputChange} />
                    <span className="text-danger">{validation.email.message}</span>
                </FormGroup>
                <div className="center">
                    <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={this.handleRecaptcha} />
                </div>
                {recaptchaError && <p className="center text-danger">Please complete captcha</p>}
                <Button variant="primary" block className="mt-4" type="submit" onClick={this.handleSubmit}>Send password reset link</Button>
                <p className="center"><span className="mr-1">Know your password?</span><Link className="link-button" to={'/'}>Login</Link></p>
            </Form>
        </React.Fragment>);
    }

    render() {
        return (
            <CardPage content={this.renderForgotContent()} />
        );
    }
}

export default withRouter(Forgot);