import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, FormControl, FormLabel, FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import FormValidator from '../common/FormValidator';
import { PUT, extractData } from '../../Consumer';
import Endpoints from '../common/Endpoints';
import LoadingBar from '../common/LoadingBar';
import { ErrorAlert, SuccessAlert } from '../common/Alerts';
import Confirm from '../common/modals/Confirm';
import { StrongPasswordPattern, NumberPattern, SpecialCharPattern, LengthPattern } from '../common/Constants'

const SAVE_PASSWORD_ERROR = 'Could not update password';

const displaySuccess = (userName, continueAction, buttonText) =>
    <React.Fragment>
        <SuccessAlert heading="Password Changed Successfully" message={`The password for ${userName} has been changed successfully.`} />
        <Button variant="primary" onClick={continueAction}>{buttonText}</Button>
    </React.Fragment>

class Password extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'password',
                method: 'matches',
                args: [StrongPasswordPattern],
                validWhen: true,
                message: 'Password does not meet the criteria, try again'
            },
            {
                field: 'password',
                 method: 'isLength',
                 args: [{min: 6, max: 50}],
                 validWhen: true,
                 message: 'Password must be between 6 and 50 characters long'
            }
        ]);

        this.state = {
            loading: false,
            validation: this.validator.valid(),
            errorMessage: '',
            displaySuccessMessage: false,
            passwordNumberCheck: false,
            passwordSpecialCharCheck: false,
            passwordLengthCheck: false,
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            user: {
                id: props.match.params.id || null,
                password: '',
            },
            validationMessages: {
                passwords: false
            },
            showCancelConfirmation: false,
            submitted: false
        };
    }

    validate = (user) => {
        let validation = this.validator.validate(user);

        if (!user.password) {
            validation.isValid = false;
            validation.password.isInvalid = true;
        }
        return validation;
    }

    handleInputChange = (e) => {
        const { value, name } = e.target;

        const numberPass = NumberPattern.test(value);
        const specialCharPass = SpecialCharPattern.test(value);
        const lengthPass = LengthPattern.test(value);

        this.setState(prevState => ({
            passwordNumberCheck: numberPass,
            passwordSpecialCharCheck: specialCharPass,
            passwordLengthCheck: lengthPass,
            user: {
                ...prevState.user,
            },
            validationMessages: {
                ...prevState.validationMessage
            }
        }));

        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { user } = this.state;
        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== '' ? Endpoints.WMS_ADMIN.USER.PUT.EDIT_PASSWORD_USER.replace("{warehouseid}", currentlySelectedWarehouse) : Endpoints.USER.PUT.EDIT_PASSWORD_USER);

        if (!user.password) user.password = '';

        const validation = this.validator.validate(user);
        this.setState({ validation: validation, loading: validation.isValid, submitted: true });

        if (validation.isValid) {
            PUT(url, user)
                .catch(error => console.log(error))
                .then(response => response.json())
                .then(results => {
                    const data = extractData(results);
                    if (data === null || data === undefined) this.setState({ loading: false, errorMessage: results.message || SAVE_PASSWORD_ERROR });
                    else this.setState({ displaySuccessMessage: true, loading: false });
                }).catch(() => this.setState({ loading: false, errorMessage: SAVE_PASSWORD_ERROR }));
        } else this.setState({ loading: validation.isValid });
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleModalShowHide = () => this.setState((prevState) => ({ showCancelConfirmation: !prevState.showCancelConfirmation }));


    handleViewAccount = () => {
        const { user } = this.state;
        this.props.history.push(`/company/users/account/overview/${user.id}`);
    }

    handleCancel = () => this.props.history.push(`/company/users/`);

    render() {
        const { loading, user, submitted, errorMessage, showCancelConfirmation, displaySuccessMessage, passwordNumberCheck, passwordSpecialCharCheck, passwordLengthCheck, passwordInputType, passwordInputIcon } = this.state;
        const { forename, surname } = this.props.location.state;
        const validation = submitted ? this.validate(user) : this.state.validation;

        return (
            loading
                ? <LoadingBar />
                : <div className="center-block mt-5">
                    <h1>Users</h1>
                    <div className="breadcrumb-container pb-3 pt-3">
                        <a onClick={this.handleCancel} className="pr-2 inline-link">Users</a>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <a onClick={this.handleViewAccount} className="pl-2 pr-2 inline-link">View User Account</a>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span className="p-2">Change password</span>
                    </div>

                    <h2>Change Password</h2>
                    {displaySuccessMessage ? displaySuccess(forename + ' ' + surname, this.handleViewAccount, 'Continue To User Account') :
                        <Row>
                            <Col sm={12} md={6}>
                                {errorMessage.length > 0 ? <ErrorAlert messages={errorMessage} /> : null}
                                <Form onSubmit={this.handleSubmit}>
                                    <fieldset>
                                        <FormGroup>
                                            <FormLabel htmlFor="password"><strong>Password</strong></FormLabel>
                                            <FormControl id="password" type={passwordInputType} name="password" value={user.password} maxLength="50" onChange={this.handleInputChange} />
                                            <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
                                            <span className="text-danger">{validation.password.message}</span>
                                        </FormGroup>
                                        <div className="form-password-checklist">
                                            <ul>
                                                <li className={passwordLengthCheck ? "pass" : "fail"}>Six characters long</li>
                                                <li className={passwordNumberCheck ? "pass" : "fail"}>One number</li>
                                                <li className={passwordSpecialCharCheck ? "pass" : "fail"}>One special character</li>
                                            </ul>
                                        </div>
                                    </fieldset>
                                    <FormGroup className="float-right mt-4">
                                        <Link to={`/company/users/account/overview/${user.id}`}>
                                            <Button variant="link" className="mr-3">Cancel</Button>
                                        </Link>
                                        <Button variant="primary" type="submit">Change Password</Button>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>}

                    <Confirm
                        title="Cancel Changing Password"
                        text={`Are you sure you want to cancel changing the user ${user.forename} ${user.surname}'s password? Changes will not be saved.`}
                        handleClose={this.handleModalShowHide}
                        show={showCancelConfirmation}
                        linkText="Continue Editing"
                        handleConfirmAction={this.handleViewAccount}
                        buttonText="Cancel"
                    />
                </div>
        );
    }
}

export default Password;