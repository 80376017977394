import React, { Fragment } from 'react';
import { NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faDollyFlatbed, faShoppingCart, faBox, faBoxes, faPallet, faExclamationTriangle, faChevronCircleLeft, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { checkPermission, checkPermissions, hasProfile } from './../Utilities';

import './NavMenu.scss';

const SideNavItem = ({ link, icon, title, exactLink = false, isDisabled = false }) =>
    <LinkContainer to={link} exact={exactLink}>
        {isDisabled
            ? <NavItem className="nav-disabled" onClick={(e) => e.preventDefault()}><FontAwesomeIcon className="mr-1" icon={icon} /> {title}</NavItem>
            : <NavItem><FontAwesomeIcon className="mr-1" icon={icon} /> {title}</NavItem>
        }
    </LinkContainer>

const NavMenu = () => {
    const viewWMSDashboard = checkPermission('ViewWMSDashboard');
    const viewUser = checkPermission('ViewUser');
    const viewWMSRuns = checkPermission('ViewWMSRuns');
    const fulfilmentProfile = hasProfile('Fulfilment');
    const viewLabelPrintQueue = fulfilmentProfile && checkPermissions(['ViewCheckinRun', 'ViewLabelPrintQueue']);
    const viewWMSInboundStock = fulfilmentProfile && checkPermissions(['ViewCheckinRun', 'ViewWMSInboundStock']);
    const viewManifests = checkPermission('ViewManifests');
    const viewReturnCheckin = checkPermission('ViewReturnCheckin');

    return (
        <div className="sidebar-wrapper pt-5" >
            <div className="mt-3">
                {(viewWMSDashboard || viewUser) && <div className="sidebar-header">Company</div>}
                {viewWMSDashboard && <SideNavItem link="/dashboard" title="Dashboard" icon={faChartBar} />}
                {viewUser && <SideNavItem link="/company/users" title="Users" icon={faChartBar} exactLink={true} />}
                {(viewWMSRuns || viewLabelPrintQueue || viewWMSInboundStock) && <div className="sidebar-header">Fulfilment</div>}
                {viewWMSRuns &&
                    <Fragment>
                        <SideNavItem link="/runs/" title="Run Queue" icon={faDollyFlatbed} isDisabled={true} />
                        <div className="ml-4 nav-sub">
                            <SideNavItem link="/runs/order" title="Orders" icon={faShoppingCart} />
                            <SideNavItem link="/runs/bulk" title="Bulk Orders" icon={faBox} />
                            <SideNavItem link="/runs/replenishment" title="Replenishment" icon={faBoxes} />
                        </div>
                    </Fragment>
                }
                {viewLabelPrintQueue && <SideNavItem link="/fulfilment/fulfilmentlabels" icon={faPallet} title="Fulfilment" />}
                {viewWMSInboundStock && <SideNavItem link="/fulfilment/inboundstock" icon={faBoxes} title="Inbound Stock" />}
                {viewManifests && <SideNavItem link="/collections/collectionmanifests" icon={faChevronCircleLeft} title="Collections" />}
                {viewWMSDashboard && <SideNavItem link="/issues" icon={faExclamationTriangle} title="Issues" />}
                {viewReturnCheckin && <SideNavItem link="/returns/inprocess" icon={faUndoAlt} title="Returns" />}
            </div>
        </div>
    );
}

export default NavMenu;