import React, { useState, useEffect, useCallback } from "react";
import LoadingBar from "../common/LoadingBar";
import { Row } from "react-bootstrap";
import Title from "../common/Title";
import { DashboardTile, DashboardTileCustomImage } from "./Snippets";
import {
  faExclamationTriangle,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { GET } from "../../Consumer";
import Endpoints from "../common/Endpoints";
import { GridErrorAlert } from "../common/Alerts";
import Tab from "../common/navigation/Tab";
import {
  buildDashboardSubNav,
  DASHBOARD_REFRESH_INTERVAL,
} from "../common/Constants";
import { useInterval } from "../../Utilities";
import "../dashboard/Dashboard.scss";

const Issues = ({ currentlySelectedWarehouse }) => {
  //#region State
  const [loading, setLoading] = useState(false);
  const [featureFlagLoading, setFeatureFlagLoading] = useState(true);
  const [problemCountsLoading, setProblemCountsLoading] = useState(false);
  const [returnCountLoading, setReturnCountLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [problemCounts, setProblemCounts] = useState(null);
  const [returnsCount, setReturnsCount] = useState(null);
  const [issuesFeatureEnabled, setIssuesFeatureEnabled] = useState(null);
  const [dashboardSubNav, setDashboardSubNav] = useState([]);

  useEffect(() => {
    setLoading(
      problemCountsLoading || returnCountLoading || featureFlagLoading
    );
  }, [problemCountsLoading, returnCountLoading, featureFlagLoading]);

  //#endregion

  //#region API

  /**
   * Feature flag
   */
  useEffect(() => {
    (async () => {
      setFeatureFlagLoading(true);
      try {
        const url = new URL(Endpoints.FULFILMENT_CONFIGURATION.GET.SEARCH);
        url.searchParams.append("query", "EnableFlagMissingItemFeature");

        const response = await GET(url);

        if (response?.ok) {
          const responseData = await response.json();
          setIssuesFeatureEnabled(responseData.data.value === 1 ? true : false);
          setShowError(false);
        } else {
          setShowError(true);
        }
      } catch (error) {
        console.log({ error });
        setShowError(true);
      } finally {
        setFeatureFlagLoading(false);
      }
    })();
  }, []);

  /**
   * Fetch data
   */
  const fetchProblemsData = useCallback(
    (handleLoading = false) => {
      if (issuesFeatureEnabled === true)
        (async () => {
          try {
            setProblemCountsLoading(handleLoading);
            const response = await GET(
              currentlySelectedWarehouse
                ? `${Endpoints.WMS_ADMIN.PROBLEMS.GET.PROBLEM_COUNTS}/${currentlySelectedWarehouse}`
                : Endpoints.WMS_ADMIN.PROBLEMS.GET.PROBLEM_COUNTS
            );

            if (response?.ok) {
              const responseData = await response.json();
              setProblemCounts(responseData.data);
              setShowError(false);
            } else {
              setShowError(true);
            }
          } catch (error) {
            console.log({ error });
            setShowError(true);
          } finally {
            setProblemCountsLoading(false);
          }
        })();
    },
    [currentlySelectedWarehouse, issuesFeatureEnabled]
  );

  const fetchReturnsData = useCallback(
    (handleLoading = false) => {
      if (issuesFeatureEnabled !== null) {
        (async () => {
          setReturnCountLoading(handleLoading);
          try {
            const response = await GET(
              currentlySelectedWarehouse
                ? `${Endpoints.WMS_ADMIN.PROBLEMS.GET.RETURN_ISSUES_COUNT}/${currentlySelectedWarehouse}`
                : Endpoints.WMS_ADMIN.PROBLEMS.GET.RETURN_ISSUES_COUNT
            );

            if (response?.ok) {
              const responseData = await response.json();
              setReturnsCount(responseData.data);
              setShowError(false);
            } else setShowError(true);
          } catch (error) {
            console.log({ error });
            setShowError(true);
          } finally {
            setReturnCountLoading(false);
          }
        })();
      }
    },
    [currentlySelectedWarehouse, issuesFeatureEnabled]
  );

  // Run on first page load.
  useEffect(() => {
    fetchProblemsData(true);
  }, [fetchProblemsData]);

  useEffect(() => {
    fetchReturnsData(true);
  }, [fetchReturnsData]);

  // Re-run data fetching on set interval.
  useInterval(fetchReturnsData, DASHBOARD_REFRESH_INTERVAL);
  useInterval(fetchProblemsData, DASHBOARD_REFRESH_INTERVAL);

  useEffect(() => {
    if (problemCounts || returnsCount) {
      setDashboardSubNav(buildDashboardSubNav());
    }
  }, [problemCounts, returnsCount]);

  //#endregion

  //#region Render

  return (
    <>
      {loading ? (
        <LoadingBar />
      ) : (
        <div className="center-block mt-5">
          <Title title="Dashboard" />
          <Tab subNavList={dashboardSubNav} activeKey="Issues" />
          {showError ? (
            <GridErrorAlert message="Could not retrieve dashboard information for this warehouse." />
          ) : (
            <Row>
              {issuesFeatureEnabled && (
                <>
                  <DashboardTile
                    icon={faMapMarkerAlt}
                    title="Location Issues"
                    count={problemCounts?.problemLocations}
                    link="/issues/locations"
                    iconClass={"card-threshold-high"}
                  />
                  <DashboardTile
                    icon={faExclamationTriangle}
                    title="Order Issues"
                    count={problemCounts?.problemPalletParcels}
                    link="/issues/parcels"
                    iconClass={"card-threshold-high"}
                  />
                </>
              )}
              <DashboardTileCustomImage
                iconURL="Images/returnissues.svg"
                iconClass="issues"
                title="Return Issues"
                count={returnsCount || 0}
                link="/returns/issues"
              />
            </Row>
          )}
        </div>
      )}
    </>
  );

  //#endregion
};

export default Issues;
