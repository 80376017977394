import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import './Breadcrumbs.scss';

const Breadcrumbs = ({ breadcrumbs = [] }) =>
    breadcrumbs.length ?
        <Row className="breadcrumb-container mb-3">
            <Col md={12}>
                {breadcrumbs.map((b, i) => <React.Fragment>
                    {b.link ?
                        <React.Fragment key={`${i}_link`}>
                            <Link className="breadcrumb-link" to={b.link}>{b.text}</Link>&nbsp;<FontAwesomeIcon className="mx-1" icon={faAngleRight} />&nbsp;
                        </React.Fragment>
                        : <span key={`${i}_active_link`}>{b.text}</span>}
                </React.Fragment>
                )}
            </Col>
        </Row>
        : null;

export default Breadcrumbs;
