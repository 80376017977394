import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SubNav from './SubNav';

const Tab = ({ title, subNavList, activeKey }) =>
    <Row>
        <Col md={12}>
            <h1 className="mb-3">{title}</h1>
            <SubNav breadcrumbs={subNavList} activeKey={activeKey} />
        </Col>
    </Row>

export default Tab;