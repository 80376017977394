import { useRef, useEffect } from "react";
import { Route } from "react-router-dom";
import { format } from "date-fns";

const getUser = () => JSON.parse(localStorage.getItem("user") || "null");

const hasTokenExpired = () => {
  const user = getUser();
  
  const now = new Date(Date.now());
  const tokenExpiry = new Date(user.expiry);

  if(tokenExpiry <= now) return true;
  else return false;

}

const tokenisedFetch = (url, init) => {
  let reqInit = init || {};
  let headers = Object.assign({}, reqInit.headers, authHeader());

  return fetch(url, { ...reqInit, headers: headers, credentials: "include" });
};

const authHeader = () => {
  const user = getUser();
  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else {
    return {};
  }
};

const hasMultipleWarehouseAccess = () => {
  const user = getUser();
  return user && user.warehouseIDs && user.warehouseIDs.length > 1;
};

const checkPermission = (permission) => {
  const user = getUser();
  return user && user.token && user.permissions.indexOf(permission) >= 0;
};

const checkPermissions = (permissions) => {
  const user = getUser();
  return (
    user &&
    user.token &&
    permissions.some((f) => user.permissions.indexOf(f) >= 0)
  );
};

const hasProfile = (profile) => {
  const user = getUser();
  return user && user.profiles.indexOf(profile) >= 0;
};

const PermissionProtectedRoute = ({ permission, path, render }) =>
  checkPermission(permission) ? (
    <Route path={path} render={render} exact />
  ) : null;

const PermissionsProtectedRoute = ({ permissions, path, render }) =>
  checkPermissions(permissions) ? (
    <Route path={path} render={render} exact />
  ) : null;

const goToURL = (url) => window.open(url, "_blank");

const formatDateTime = (dateTimeString) =>
  format(new Date(dateTimeString), "dd MMM yyyy") +
  " at " +
  format(new Date(dateTimeString), "HH:mm");

const setFilterData = (name, data) =>
  localStorage.setItem(name, JSON.stringify(data));

const getFilterData = (name) =>
  JSON.parse(localStorage.getItem(name) || "null");

const removeFilterData = (name) => localStorage.removeItem(name);

const toLocalTimeString = (dateString) => {
  const date = new Date(dateString);
  const newDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000
  );

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);
  return (
    newDate.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }) +
    " at " +
    addLeadingZero(newDate.getHours()) +
    ":" +
    addLeadingZero(newDate.getMinutes())
  );
};

const addLeadingZero = (number) => ("0" + number).slice(-2);

const isNullOrEmptyGuid = (guid) =>
  guid === "00000000-0000-0000-0000-000000000000" ||
  guid === null ||
  guid === "" ||
  guid === 0 ||
  guid === "0" ||
  guid === undefined;

/**
 * Executes a callback function with a set interval.
 * Reference: https://blog.bitsrc.io/polling-in-react-using-the-useinterval-custom-hook-e2bcefda4197
 * @param {() => void} callback The callback to execute after each interval
 * @param {Number} delay The interval between callback executions
 */
const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
};

export {
  authHeader,
  tokenisedFetch,
  checkPermission,
  checkPermissions,
  hasProfile,
  PermissionProtectedRoute,
  PermissionsProtectedRoute,
  goToURL,
  formatDateTime,
  setFilterData,
  getFilterData,
  removeFilterData,
  toLocalTimeString,
  isNullOrEmptyGuid,
  hasMultipleWarehouseAccess,
  addLeadingZero,
  useInterval,
  getUser,
  hasTokenExpired
};
