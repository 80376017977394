import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Tab from "../common/navigation/Tab";
import { IssuesSubNav, DASHBOARD_REFRESH_INTERVAL } from "../common/Constants";
import GenericModal from "../common/modals/GenericModal";
import LoadingBar from "../common/LoadingBar";
import { Row, Col, Button, Form, Badge } from "react-bootstrap";
import { ErrorAlert } from "../../components/common/Alerts";
import Endpoints from "../common/Endpoints";
import { GET, PUT } from "../../Consumer";
import { useParams } from "react-router-dom";
import { useInterval, checkPermission } from "../../Utilities";
import { useFormValidator } from "@selazar-ui/core";

const CANNOT_RESOLVE_PROBLEM_PARCEL_ERROR =
  "An error occurred whilst attempting to resolve the problem parcel. Please refresh the page to try again.";

const CANNOT_RETRIEVE_PROBLEM_PARCEL_ERROR =
  "Could not retrieve parcel issue information for this parcel.";

const FEATURE_NOT_ENABLED_ERROR =
  "This feature is not currently enabled, please contact support.";

const initialState = {
  partialFulfil: false,
  cancel: false,
  note: "",
};

const ParcelDetails = ({ currentlySelectedWarehouse }) => {
  //#region State

  const [loading, setLoading] = useState(true);
  const [featureFlagRequestLoading, setFeatureFlagRequestLoading] =
    useState(true);
  const [fetchDataRequestLoading, setFetchDataRequestLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPartialFulfilModal, setShowPartialFulfilModal] = useState(false);
  const [model, setModel] = useState(initialState);
  const [renders, setRenders] = useState(0);
  const [issuesFeatureEnabled, setIssuesFeatureEnabled] = useState(false);
  const [showButtons] = useState(checkPermission("ResolveProblems"));

  useEffect(() => {
    setLoading(featureFlagRequestLoading || fetchDataRequestLoading);
  }, [featureFlagRequestLoading, fetchDataRequestLoading]);

  //#endregion

  //#region API

  /**
   * Feature flag
   */
  useEffect(() => {
    (async () => {
      setFeatureFlagRequestLoading(true);
      try {
        const url = new URL(Endpoints.FULFILMENT_CONFIGURATION.GET.SEARCH);

        url.searchParams.append("query", "EnableFlagMissingItemFeature");

        const response = await GET(url);

        if (response?.ok) {
          const responseData = await response.json();
          const enableFeature = responseData.data.value === 1 ? true : false;
          setIssuesFeatureEnabled(enableFeature);
          enableFeature
            ? setErrorMessage("")
            : setErrorMessage(FEATURE_NOT_ENABLED_ERROR);
        } else {
          setErrorMessage(FEATURE_NOT_ENABLED_ERROR);
        }
      } catch (error) {
        console.log({ error });
        setErrorMessage(CANNOT_RETRIEVE_PROBLEM_PARCEL_ERROR);
      } finally {
        setFeatureFlagRequestLoading(false);
      }
    })();
  }, []);

  /**
   * Fetch data
   */
  const fetchData = useCallback(
    (handleLoading = false) => {
      if (issuesFeatureEnabled)
        (async () => {
          setFetchDataRequestLoading(handleLoading);
          try {
            const response = await GET(
              currentlySelectedWarehouse != null
                ? `${Endpoints.WMS_ADMIN.PROBLEMS.GET.PROBLEM_PALLET_PARCEL.replace(
                    "{id}",
                    id
                  )}/${currentlySelectedWarehouse}`
                : Endpoints.WMS_ADMIN.PROBLEMS.GET.PROBLEM_PALLET_PARCEL.replace(
                    "{id}",
                    id
                  )
            );

            if (response?.ok) {
              const { data } = await response.json();
              setData(data);
              setErrorMessage("");
            } else {
              setErrorMessage(CANNOT_RETRIEVE_PROBLEM_PARCEL_ERROR);
            }
          } catch (error) {
            console.log({ error });
            setErrorMessage(CANNOT_RETRIEVE_PROBLEM_PARCEL_ERROR);
          } finally {
            setFetchDataRequestLoading(false);
          }
        })();
    },
    [id, currentlySelectedWarehouse, issuesFeatureEnabled]
  );

  // Run on first page load.
  useEffect(() => {
    fetchData(true);
  }, [fetchData, renders, issuesFeatureEnabled]);

  // Re-run data fetching on set interval.
  useInterval(fetchData, DASHBOARD_REFRESH_INTERVAL);

  /**
   * Resolve problem parcel
   */
  const resolveProblemParcel = useCallback(
    async (model) => {
      setLoading(true);
      try {
        const url = new URL(
          currentlySelectedWarehouse
            ? `${Endpoints.WMS_ADMIN.PROBLEMS.PUT.RESOLVE_PROBLEM_PARCEL.replace(
                "{id}",
                id
              )}/${currentlySelectedWarehouse}`
            : Endpoints.WMS_ADMIN.PROBLEMS.PUT.RESOLVE_PROBLEM_PARCEL.replace(
                "{id}",
                id
              )
        );

        const response = await PUT(url, model);

        if (response?.ok) {
          setRenders((prevValue) => prevValue + 1); //to trigger a re-render
          setErrorMessage("");
        } else {
          setErrorMessage(CANNOT_RESOLVE_PROBLEM_PARCEL_ERROR);
        }
      } catch (error) {
        console.log({ error });
        setErrorMessage(CANNOT_RESOLVE_PROBLEM_PARCEL_ERROR);
      } finally {
        setLoading(false);
      }
    },
    [currentlySelectedWarehouse, id]
  );

  //#endregion

  //#region Controls

  const handlePartialFulfilClose = () => {
    setShowPartialFulfilModal(false);
  };

  const handlePartialFulfilSecondaryAction = () => {
    handlePartialFulfilClose();
  };

  const handlePartialFulfilPrimaryAction = async () => {
    var validation = validate(model);

    if (validation?.isValid) {
      handlePartialFulfilClose();
      await resolveProblemParcel(model);
    }
  };

  const handleCancelClose = () => {
    setShowCancelModal(false);
  };

  const handleCancelSecondaryAction = () => {
    handleCancelClose();
  };

  const handleCancelPrimaryAction = async () => {
    var validation = validate(model);

    if (validation?.isValid) {
      handleCancelClose();
      await resolveProblemParcel(model);
    }
  };

  const handleInputChange = (event) => {
    setModel((prevValue) => ({ ...prevValue, note: event.target.value }));
  };

  const openCancelModal = () => {
    setModel({
      ...initialState,
      cancel: true,
    });
    setShowCancelModal(true);
  };

  const openPartialFulfilModal = () => {
    setModel({
      ...initialState,
      partialFulfil: true,
    });
    setShowPartialFulfilModal(true);
  };

  const { validate, validationResults } = useFormValidator([
    {
      field: "note",
      method: "isEmpty",
      validWhen: false,
      message: "A note is required",
    },
    {
      field: "note",
      method: "isLength",
      args: [{ min: 10, max: 1000 }],
      validWhen: true,
      message: "Note must be more than 10 characters long",
    },
  ]);

  //#endregion

  //#region Render

  if (loading) return <LoadingBar />;

  return (
    <div className="center-block mt-5">
      <Tab title="Issues" subNavList={IssuesSubNav} activeKey="Parcels" />
      <Link to="/issues/parcels">
        <FontAwesomeIcon className="mr-2" icon={faChevronLeft} />
        Return to issues
      </Link>
      {errorMessage !== "" ? (
        <Row className="mt-3">
          <Col sm={12} md={6}>
            <ErrorAlert messages={errorMessage} />
          </Col>
        </Row>
      ) : (
        data && (
          <section className="section-first">
            <h2>Issue Parcel Overview</h2>

            <p className="section-title">Retailer Name</p>
            <p>{data.companyName}</p>

            <p className="section-title mt-4">Missing Items</p>
            {data?.itemsMissing?.map((missingItem) => {
              let name;
              let location;
              let skuCode;
              let selazarUniqueID;
              let ean;

              if (missingItem?.pickAndPackCompanyItem !== null) {
                name =
                  missingItem.pickAndPackCompanyItem?.companyItem?.item?.name;
                location =
                  missingItem.pickAndPackCompanyItem?.supplierBox?.location;
                skuCode = missingItem.pickAndPackCompanyItem?.skuCode;
                selazarUniqueID =
                  missingItem.pickAndPackCompanyItem?.companyItem?.item
                    ?.selazarUniqueID;
                ean =
                  missingItem.pickAndPackCompanyItem?.companyItem?.item?.ean;
              } else {
                name = missingItem?.palletCompanyItem?.companyItem?.item?.name;
                location =
                  missingItem?.palletCompanyItem?.pallet?.palletStorageLocation
                    ?.location;
                skuCode = missingItem?.palletCompanyItem?.stockCSVLine?.skuCode;
                selazarUniqueID =
                  missingItem?.palletCompanyItem?.companyItem?.item
                    ?.selazarUniqueID;
                ean = missingItem?.palletCompanyItem?.companyItem?.item?.ean;
              }

              return (
                <React.Fragment
                  key={`missingPickAndPackItem-${missingItem.id}`}
                >
                  <p>
                    {name}
                    {location && " - " + location}
                    {skuCode && " - " + skuCode}
                    {selazarUniqueID
                      ? " - " + selazarUniqueID
                      : ean && " - " + ean}
                  </p>

                  <p>
                    Quantity Missing: <strong>{missingItem?.quantity}</strong>
                  </p>
                </React.Fragment>
              );
            })}

            <p className="section-title mt-4">Items that should be in parcel</p>
            {data?.itemsPresent?.map((item) => (
              <React.Fragment key={`pickAndPackItem-${item.id}`}>
                <p>
                  {item?.pickAndPackCompanyItem !== null
                    ? item.pickAndPackCompanyItem?.companyItem?.item?.name
                    : item?.palletCompanyItem?.companyItem?.item?.name}
                  {" - "}
                  {item?.pickAndPackCompanyItem !== null
                    ? item.pickAndPackCompanyItem?.skuCode
                    : item?.palletCompanyItem?.stockCSVLine?.skuCode}
                  {" - "}

                  {item?.pickAndPackCompanyItem !== null
                    ? item.pickAndPackCompanyItem?.companyItem?.item?.ean ||
                      item.pickAndPackCompanyItem?.companyItem?.item
                        ?.selazarUniqueID
                    : item?.palletCompanyItem?.companyItem?.item?.ean ||
                      item?.palletCompanyItem?.companyItem?.item
                        ?.selazarUniqueID}
                </p>

                <p>
                  Quantity Present: <strong>{item?.quantity}</strong>
                </p>
              </React.Fragment>
            ))}

            <p className="section-title mt-4">Picker</p>
            <p>{data.pickerEmail}</p>

            <div className="mt-3">
              {data?.problemPalletParcel.resolved ||
              data?.problemPalletParcel.cancel ||
              data?.problemPalletParcel.partialFulfil ? (
                <>
                  {data?.problemPalletParcel.partialFulfil && (
                    <Badge className="purple">Part Fulfilled</Badge>
                  )}
                  {data?.problemPalletParcel.cancel && (
                    <Badge className="red">Cancelled</Badge>
                  )}
                </>
              ) : (
                showButtons && (
                  <>
                    <Button className="mr-4" onClick={openPartialFulfilModal}>
                      Partially Fulfil Order
                    </Button>

                    <Button variant="outline-primary" onClick={openCancelModal}>
                      Cancel Order
                    </Button>
                  </>
                )
              )}
            </div>

            <GenericModal
              title="Partially Fulfil Order"
              handleClose={handlePartialFulfilClose}
              show={showPartialFulfilModal}
              secondaryAction={handlePartialFulfilSecondaryAction}
              secondaryActionText="Cancel"
              primaryAction={handlePartialFulfilPrimaryAction}
              primaryActionText="Submit"
            >
              {
                <Form>
                  <p>
                    Are you sure the customer has approved partial fulfilment of
                    this order?
                  </p>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Note of approval for partial fulfilment..."
                    value={model.note}
                    onChange={handleInputChange}
                  />
                  <span className="text-danger small">
                    {validationResults?.note?.message}
                  </span>
                </Form>
              }
            </GenericModal>

            <GenericModal
              title="Cancel Order"
              handleClose={handleCancelClose}
              show={showCancelModal}
              secondaryAction={handleCancelSecondaryAction}
              secondaryActionText="Cancel"
              primaryAction={handleCancelPrimaryAction}
              primaryActionText="Submit"
            >
              {
                <Form>
                  <p>
                    Are you sure the customer has approved cancelling fulfilment
                    of this order?
                  </p>
                  <p>Please state approval and reason for cancellation.</p>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Note of approval for cancellation..."
                    value={model.note}
                    onChange={handleInputChange}
                  />
                  <span className="text-danger small">
                    {validationResults?.note?.message}
                  </span>
                </Form>
              }
            </GenericModal>
          </section>
        )
      )}
    </div>
  );

  //#endregion
};

export default ParcelDetails;
