import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button } from 'react-bootstrap';
import CardPage from '../../common/CardPage';

class Complete extends Component {

    handleReturnLogin = () => this.props.history.push({ pathname: "/" });

    renderCompleteContent = () => (
        <React.Fragment>
            <h3>Password Reset Successfully</h3>
            <p>Your Selzar password has been successfully reset.</p>
            <div className="d-block text-center m-5">
                <img src="Images/operating.svg" alt="" height="150" />
            </div>
            <Button variant="primary" block type="submit" value="Submit" onClick={this.handleReturnLogin}>Login</Button>
        </React.Fragment>
    );

    render() {
        return (
            <CardPage content={this.renderCompleteContent()} />
        );
    }
}

export default withRouter(Complete);