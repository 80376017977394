import React, { Component } from 'react';
import FormValidator from '../common/FormValidator';
import { Row, Col, Form, FormControl, FormLabel, FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { GET, PUT, extractData } from '../../Consumer';
import Endpoints from '../common/Endpoints';
import LoadingBar from '../common/LoadingBar';
import { ErrorAlert, SuccessAlert } from '../common/Alerts';
import { UsersSubNav } from '../common/Constants'
import Tab from '../common/navigation/Tab';
import Confirm from '../common/modals/Confirm';
import { getUser } from '../../Utilities';

const ROLES_ERROR_MESSAGE = 'Could not retrieve roles';
const USER_ERROR_MESSAGE = 'Could not retrieve user';
const UPDATE_ERROR_MESSAGE = 'Could not update user';
const ROLE_VALIDATION_MESSAGE = 'You need to select at least one user preference to continue';
const VALIDATION_ERROR_MESSAGE = 'There seems to be a problem with some of the details entered, try again.';

const displaySuccess = (userName, continueAction, buttonText) =>
    <React.Fragment>
        <SuccessAlert heading="Changes Saved Successfully" message={`The changes made to ${userName} have been saved successfully.`} />
        <Button variant="primary" onClick={continueAction}>{buttonText}</Button>
    </React.Fragment>

class EditDetails extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'forename',
                method: 'isEmpty',
                validWhen: false,
                message: `Enter the warehouse user's first name`
            },
            {
                field: 'surname',
                method: 'isEmpty',
                validWhen: false,
                message: `Enter the warehouse user's last name`
            },
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: `Enter the warehouse user's email`
            }]
        );

        this.state = {
            roleOptions: {},
            loading: true,
            validation: this.validator.valid(),
            errorMessage: '',
            errorFetch: null,
            success: false,
            userID: props.match.params.id || null,
            user: {
                id: '',
                forename: '',
                surname: '',
                email: '',
                roles: []
            },
            validationMessages: {
                roles: false,
            },
            showCancelConfirmation: false,
            displaySuccessMessage: false,
            submitted: false
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchUser(),
            this.fetchRoleOptions()
        ]);
    }

    handleModalShowHide = () => this.setState((prevState) => ({ showCancelConfirmation: !prevState.showCancelConfirmation }));

    validate = (user) => this.validator.validate(user);

    fetchUser = () => {
        const { userID } = this.state;
        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== '' ? Endpoints.WMS_ADMIN.USER.GET.GET_USER_WITH_ROLES.replace("{warehouseid}", currentlySelectedWarehouse).replace("{id}", userID) : Endpoints.USER.GET.GET_USER_WITH_ROLES.replace("{id}", userID));

        return GET(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ errorMessage: USER_ERROR_MESSAGE, loading: false });
                else {
                    const user = { ...data, roles: data.userRoles.map((ur) => ur.roleID) };
                    this.setState({ user: user, loading: false });
                }
            }).catch(() => this.setState({ showError: true, errorMessage: USER_ERROR_MESSAGE, loading: false }));
    }


    fetchRoleOptions = () => {
        const { currentlySelectedWarehouse } = this.props;
        const url = new URL(currentlySelectedWarehouse !== '' ? Endpoints.WMS_ADMIN.USER.GET.GET_ROLE_OPTIONS_FOR_COMPANY.replace("{warehouseid}", currentlySelectedWarehouse) : Endpoints.USER.GET.GET_ROLE_OPTIONS_FOR_COMPANY);

        return GET(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ errorMessage: ROLES_ERROR_MESSAGE, loading: false });
                else this.setState({ roleOptions: data, loading: false });
            }).catch(() => this.setState({ errorMessage: ROLES_ERROR_MESSAGE, loading: false }));
    }

    handleInputChange = (e) => {
        const { name, value, type } = e.target;

        if (type === 'checkbox') {
            const currRole = this.state.user.roles.indexOf(name);
            if (currRole > -1) {
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: [...prevState.user.roles.slice(0, currRole), ...prevState.user.roles.slice(currRole + 1)] }
                    }
                }));
            }
            else {
                const newRoles = this.state.user.roles;
                newRoles.push(name);
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: newRoles }
                    }
                }));
            }
        }
        else {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    ...{ [name]: value }
                }
            }));
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { user } = this.state;
        const { currentlySelectedWarehouse } = this.props;

        const userFromStorage = getUser();
        const warehouseID = currentlySelectedWarehouse === null || currentlySelectedWarehouse === undefined || currentlySelectedWarehouse === '' 
        ? userFromStorage.warehouseIDs[0] : currentlySelectedWarehouse;
     
        const url = new URL(Endpoints.WMS_ADMIN.USER.PUT.EDIT_USER_DETAILS.replace("{warehouseid}", warehouseID));
        const validation = this.validate(user);
        this.setState({ validation: validation, loading: validation.isValid, submitted: true });

        if (user.roles.length === 0) {
            validation.isValid = false;
            this.setState({ validationMessages: { roles: true } });
        }

        if (validation.isValid) {
            PUT(url, user)
                .then(res => res.json())
                .then(results => {
                    const data = extractData(results);
                    if (data === null || data === undefined) this.setState({ errorMessage: results.message || UPDATE_ERROR_MESSAGE, loading: false, displaySuccessMessage: false });
                    else this.setState({ displaySuccessMessage: true, loading: false, errorMessage: '' });
                })
                .catch(() => this.setState({ errorMessage: UPDATE_ERROR_MESSAGE, loading: false, displaySuccessMessage: false }));
        } else this.setState({ loading: validation.isValid, errorMessage: VALIDATION_ERROR_MESSAGE });

    }

    handleModalCancel = () => {
        const { user } = this.state;
        const showTab = this.props.location.state === undefined ? '' : this.props.location.state.showTabEditUser;
        showTab ? this.props.history.push(`/company/users/`) : this.props.history.push(`/company/users/account/overview/${user.id}`);
    }

    handleViewAccount = () => {
        const { user } = this.state;
        this.props.history.push(`/company/users/account/overview/${user.id}`);
    }

    render() {
        const { loading, submitted, displaySuccessMessage, errorMessage, user, validationMessages, showCancelConfirmation } = this.state;
        const showTab = this.props.location.state === undefined ? '' : this.props.location.state.showTabEditUser;
        const validation = submitted ? this.validate(user) : this.state.validation;
        const loggedInUser = JSON.parse(localStorage.getItem('user'));

        if (submitted) {
            if (user.roles.length === 0) {
                validation.isValid = false;
                validationMessages.roles = true;
            } else validationMessages.roles = false;
        }

        return (
            loading
                ? <LoadingBar />
                : <div className="center-block mt-5">
                    {showTab ? <Tab title="Edit User" subNavList={UsersSubNav} activeKey="Users" /> :
                        <React.Fragment>
                            <h1>Users</h1>
                            <div className="breadcrumb-container pb-3 pt-3">
                                <a onClick={this.handleModalCancel} className="pr-2 inline-link">Users</a>
                                <FontAwesomeIcon icon={faAngleRight} />
                                <a onClick={this.handleViewAccount} className="pl-2 pr-2 inline-link">View User Account</a>
                                <FontAwesomeIcon icon={faAngleRight} />
                                <span className="p-2">Edit User</span>
                            </div>
                        </React.Fragment>
                    }
                    <h2>Edit User</h2>
                    {displaySuccessMessage ? displaySuccess(user.forename + ' ' + user.surname, showTab ? this.handleModalCancel : this.handleViewAccount, showTab ? 'Continue to Users' : 'Continue To User Account') :
                        <Row>
                            <Col sm={12} md={6}>
                                {errorMessage.length > 0 ? <ErrorAlert messages={errorMessage} /> : null}
                                <Form onSubmit={this.handleSubmit} className="mt-4">
                                    <input type="hidden" name="id" value={user.id} />
                                    <h3>User Details</h3>
                                    <fieldset>
                                        <FormGroup>
                                            <FormLabel htmlFor="forename">First Name</FormLabel>
                                            <FormControl id="forename" type="text" name="forename" value={user.forename} onChange={this.handleInputChange} />
                                            <span className="text-danger">{validation.forename.message}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel htmlFor="surname">Last Name</FormLabel>
                                            <FormControl id="surname" type="text" name="surname" value={user.surname} onChange={this.handleInputChange} />
                                            <span className="text-danger">{validation.surname.message}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel htmlFor="email">Email</FormLabel>
                                            <FormControl id="email" type="email" name="email" autoComplete="off" value={user.email} onChange={this.handleInputChange} />
                                            <span className="text-danger">{validation.email.message}</span>
                                        </FormGroup>
                                    </fieldset>

                                    {loggedInUser.id !== user.id && Object.keys(this.state.roleOptions).map((k) =>
                                        <div key={k} className="py-2">
                                            <h3 className="pb-2">{k} Roles</h3>
                                            {validationMessages.roles && <span className="text-danger">{ROLE_VALIDATION_MESSAGE}</span>}
                                            {this.state.roleOptions[k].map((r) =>
                                                <FormGroup key={r.id} className="custom-control custom-checkbox mb-1">
                                                    <input className="custom-control-input" id={r.id} type="checkbox" name={r.id} onChange={this.handleInputChange} checked={user.roles.indexOf(r.id) > -1} />
                                                    <FormLabel className="custom-control-label" htmlFor={r.id}><p>{r.name}</p></FormLabel>
                                                </FormGroup>
                                            )}

                                        </div>
                                    )}
                                    <FormGroup className="float-right mt-4">
                                        <Button variant="link" onClick={this.handleModalShowHide} className="mr-3">Cancel</Button>
                                        <Button variant="primary" type="submit">Save changes</Button>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>}

                    <Confirm
                        title="Cancel Editing User"
                        text={`Are you sure you want to cancel editing the user ${user.forename} ${user.surname}? Changes will not be saved.`}
                        handleClose={this.handleModalShowHide}
                        show={showCancelConfirmation}
                        linkText="Continue Editing"
                        handleConfirmAction={this.handleModalCancel}
                        buttonText="Cancel"
                    />
                </div>
        );
    }
}

export default EditDetails;