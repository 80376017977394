import React from "react";
import { Modal, Button } from "react-bootstrap";

import "./Confirm.scss";

const Confirm = ({
  title,
  text,
  handleClose,
  show,
  handleConfirmAction,
  linkText = "Cancel",
  buttonText,
  variant = "primary",
}) => (
  <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{text}</p>
    </Modal.Body>
    <Modal.Footer className="pt-0 mb-2">
      <Button variant="link" onClick={handleClose} style={{ minWidth: 100 }}>
        {linkText}
      </Button>
      <Button
        variant={variant}
        onClick={handleConfirmAction}
        style={{ minWidth: 100 }}
      >
        {buttonText}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default Confirm;
