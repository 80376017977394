import React, { useState, useEffect } from "react";
import Tab from "../common/navigation/Tab";
import { IssuesSubNav, IssueParcelTableHeaders } from "../common/Constants";
import LoadingBar from "../common/LoadingBar";
import { ErrorAlert } from "../../components/common/Alerts";
import PagingTable from "../common/tables/PagingTable";
import Endpoints from "../common/Endpoints";
import { GET } from "../../Consumer";
import { NoPendingRuns } from "../runs/Snippets";
import { toLocalTimeString } from "../../Utilities";
import { Link } from "react-router-dom";

import { Form, Row, Col, Button } from "react-bootstrap";

const CANNOT_RETRIEVE_DATA_ERROR =
  "Could not retrieve parcel issue information for this warehouse.";
const FEATURE_NOT_ENABLED_ERROR =
  "This feature is not currently enabled, please contact support.";

const Parcels = ({ currentlySelectedWarehouse }) => {
  //#region State

  const [loading, setLoading] = useState(true);
  const [featureFlagRequestLoading, setFeatureFlagRequestLoading] =
    useState(true);
  const [fetchDataRequestLoading, setFetchDataRequestLoading] = useState(false);

  const [showError, setShowError] = useState("");

  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [rows, setRows] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [issuesFeatureEnabled, setIssuesFeatureEnabled] = useState(false);

  useEffect(() => {
    setLoading(featureFlagRequestLoading || fetchDataRequestLoading);
  }, [featureFlagRequestLoading, fetchDataRequestLoading]);

  //#endregion

  //#region API

  /**
   * Feature flag
   */
  useEffect(() => {
    (async () => {
      setFeatureFlagRequestLoading(true);
      try {
        const url = new URL(Endpoints.FULFILMENT_CONFIGURATION.GET.SEARCH);

        url.searchParams.append("query", "EnableFlagMissingItemFeature");

        const response = await GET(url);

        if (response?.ok) {
          const responseData = await response.json();
          const featureEnabled = responseData.data.value === 1 ? true : false;
          setIssuesFeatureEnabled(featureEnabled);
          featureEnabled
            ? setShowError("")
            : setShowError(FEATURE_NOT_ENABLED_ERROR);
        } else {
          setShowError(CANNOT_RETRIEVE_DATA_ERROR);
        }
      } catch (error) {
        console.log({ error });
        setShowError(CANNOT_RETRIEVE_DATA_ERROR);
      } finally {
        setFeatureFlagRequestLoading(false);
      }
    })();
  }, []);

  /**
   * Fetch data
   */
  useEffect(() => {
    if (issuesFeatureEnabled)
      (async () => {
        setFetchDataRequestLoading(true);
        try {
          const url = new URL(
            currentlySelectedWarehouse !== null
              ? `${Endpoints.WMS_ADMIN.PROBLEMS.GET.PROBLEM_PALLET_PARCELS}/${currentlySelectedWarehouse}`
              : Endpoints.WMS_ADMIN.PROBLEMS.GET.PROBLEM_PALLET_PARCELS
          );

          url.searchParams.append("search", searchTerm);
          url.searchParams.append("pageIndex", pageIndex);
          const response = await GET(url);

          if (response?.ok) {
            setShowError("");
            const {
              data: { pageCount, problemPalletParcels },
            } = await response.json();

            var rows = [];
            problemPalletParcels.forEach((probs) => {
              rows.push([
                <Link to={`/issues/parcels/${probs.id}`}>
                  {probs.orderCustomerReference}
                </Link>,
                probs.missingItemsCount,
                probs.pickerEmail,
                toLocalTimeString(probs.orderCreatedDate),
              ]);
            });
            setRows(rows);
            setPageCount(pageCount);
          } else {
            setShowError(CANNOT_RETRIEVE_DATA_ERROR);
          }
        } catch (error) {
          console.log({ error });
          setShowError(CANNOT_RETRIEVE_DATA_ERROR);
        } finally {
          setFetchDataRequestLoading(false);
        }
      })();
  }, [currentlySelectedWarehouse, issuesFeatureEnabled, pageIndex, searchTerm]);

  //#endregion

  //#region Controls

  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchTerm(searchValue);
  };

  const onNext = () => {
    if (pageIndex < pageCount) setPageIndex((index) => index + 1);
  };

  const onPrevious = () => {
    if (pageIndex > 1) setPageIndex((index) => index - 1);
  };

  const onStart = () => setPageIndex(1);
  const onEnd = () => setPageIndex(pageCount);

  //#endregion

  //#region Render

  if (loading) return <LoadingBar />;

  return (
    <div className="center-block mt-5">
      <Tab title="Issues" subNavList={IssuesSubNav} activeKey="Parcels" />
      {showError !== "" ? (
        <Row>
          <Col sm={12} md={6}>
            <ErrorAlert messages={showError} />
          </Col>
        </Row>
      ) : (
        rows && (
          <>
            <Form onSubmit={handleSearch} className="mt-3 mb-4">
              <Row>
                <Col>
                  <Form.Control
                    type="search"
                    onChange={handleSearchValueChange}
                    value={searchValue}
                    placeholder="Search by order number"
                  />
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button type="submit" variant="primary">
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>

            {rows.length === 0 && <NoPendingRuns />}

            <PagingTable
              headers={IssueParcelTableHeaders}
              rows={rows}
              onNext={onNext}
              onPrevious={onPrevious}
              onStart={onStart}
              onEnd={onEnd}
              pageIndex={pageIndex}
              pageCount={pageCount}
            />
          </>
        )
      )}
    </div>
  );

  //#endregion
};

export default Parcels;
