import React, { Fragment, useState, useEffect, useCallback } from "react";
import Tab from "../common/navigation/Tab";
import { ReturnsSubNav, ReturnBoxLabelTableHeaders } from "../common/Constants";
import LoadingBar from "../common/LoadingBar";
import { GET, PUT } from '../../Consumer';
import Endpoints from "../common/Endpoints";
import { GridErrorAlert, GridInfoAlert } from '../common/Alerts';
import PagingTable from "../common/tables/PagingTable";
import { Button } from "react-bootstrap";

const BoxLabels = ({ currentlySelectedWarehouse }) => {
  
  //#region State
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [rows, setRows] = useState([]);
  const [renderCount, setRenderCount] = useState(0);

  //#endregion

  //#region API

  const markPrinted = useCallback(async (id) => {
    try {
      const urlString = Endpoints.FULFILMENT.PUT.PRINT_RESTOCK_BIN_ITEM_BOX_LABEL.replace("{id}", id)
      const url = new URL(currentlySelectedWarehouse !== '' ? `${urlString}/${currentlySelectedWarehouse}` : urlString);

      const response = await PUT(url);
      if (response?.ok) {
          const responseData = await response.json();
          if (responseData) {
              setRenderCount(currentCount => currentCount + 1);
          }
          setShowError(false);
      }
      else {
          setShowError(true);
      }
    } catch (error) {
        console.log(error);
        setShowError(true);
    } 
}, [currentlySelectedWarehouse]);

  const download = useCallback(async (id) => {
    setLoading(true);

    try {
      const urlString = Endpoints.FULFILMENT.GET.DOWNLOAD_RESTOCK_BIN_ITEM_BOX_LABEL.replace("{id}", id)
      const url = new URL(currentlySelectedWarehouse !== '' ? `${urlString}/${currentlySelectedWarehouse}` : urlString);
      const response = await GET(url);

      if (response?.ok) {
        const manifestBlob = await response.blob();
        const objectURL = URL.createObjectURL(manifestBlob);
        window.open(objectURL);
        await markPrinted(id);
        setShowError(false);
      } else { setShowError(true); }
    } catch (error) {
      console.log({ error });
      setShowError(true);
    } finally {
      setLoading(false);
    }
  }, [currentlySelectedWarehouse, markPrinted]);

/**
   * Fetch data
   */
 useEffect(() => {
  (async () => {
    setLoading(true);

    try {
      const url = new URL(currentlySelectedWarehouse !== '' ? `${Endpoints.FULFILMENT.GET.RESTOCK_BIN_ITEM_BOX_LABELS}/${currentlySelectedWarehouse}`
         : Endpoints.FULFILMENT.GET.RESTOCK_BIN_ITEM_BOX_LABELS);

      url.searchParams.append("pageIndex", pageIndex);
      const response = await GET(url);

      if (response?.ok) {
        setShowError(false);
        const {
          data: { pageCount, restockBinItemBoxLabels },
        } = await response.json();

        var rows = [];
        restockBinItemBoxLabels.forEach((restockBinItemBoxLabel) => {
          rows.push([
            restockBinItemBoxLabel.stockCSVLine.name,
            restockBinItemBoxLabel.stockCSVLine.skuCode,
            <Button variant="secondary" className="m-0" onClick={() => download(restockBinItemBoxLabel.id)}>Print</Button>,
          ]);
        });
        setRows(rows);
        setPageCount(pageCount);
      } else {
        setShowError(true);
      }
    } catch (error) {
      console.log({ error });
      setShowError(true);
    } finally {
      setLoading(false);
    }
  })();
}, [currentlySelectedWarehouse, pageIndex, download, renderCount]);

  //#endregion

  //#region Controls

  const onNext = () => { if (pageIndex < pageCount) setPageIndex((index) => index + 1); };
  const onPrevious = () => { if (pageIndex > 1) setPageIndex((index) => index - 1); };
  const onStart = () => setPageIndex(1);
  const onEnd = () => setPageIndex(pageCount);

  //#endregion

  return (
    <div className="center-block mt-5">
      {loading ? (
        <LoadingBar />
      ) : (
        <>
          <Tab title="Returns" subNavList={ReturnsSubNav} activeKey="Box Labels" />
          {showError ? <GridErrorAlert message="Could not retrieve return box labels for this warehouse." /> 
          : <Fragment>
              {rows.length === 0 ? <GridInfoAlert message="There are no box labels at this time." />
              : <PagingTable
                  headers={ReturnBoxLabelTableHeaders}
                  rows={rows}
                  onNext={onNext}
                  onPrevious={onPrevious}
                  onStart={onStart}
                  onEnd={onEnd}
                  pageIndex={pageIndex}
                  pageCount={pageCount} />}
            </Fragment>
          }
        </>
      )}
    </div>
  );
};

export default BoxLabels;