import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, withRouter, Redirect, useHistory, useLocation } from 'react-router-dom';
import { GET, extractData } from './Consumer';
import Endpoints from './components/common/Endpoints';
import { checkPermission, hasMultipleWarehouseAccess, hasProfile, PermissionProtectedRoute, PermissionsProtectedRoute, getUser, hasTokenExpired, useInterval } from './Utilities';
import Layout from './layout/Layout';
import Login from './components/login/Login';
import Forgot from './components/account/password/Forgot';
import Sent from './components/account/password/Sent';
import Expired from './components/account/password/Expired';
import Reset from './components/account/password/Reset';
import Complete from './components/account/password/Complete';
import QueuedRuns from './components/dashboard/QueuedRuns';
import InProgressRuns from './components/dashboard/InProgressRuns';
import Issues from './components/dashboard/Issues';
import OrderPick from './components/runs/order/OrderPick';
import OrderPack from './components/runs/order/OrderPack';
import OrderPickDetails from './components/runs/order/OrderPickDetails';
import OrderPackDetails from './components/runs/order/OrderPackDetails';
import BulkShelf from './components/runs/bulk/BulkShelf';
import BulkPallet from './components/runs/bulk/BulkPallet';
import BulkPack from './components/runs/bulk/BulkPack';
import BulkShelfDetails from './components/runs/bulk/BulkShelfDetails';
import BulkPalletDetails from './components/runs/bulk/BulkPalletDetails';
import BulkPackDetails from './components/runs/bulk/BulkPackDetails';
import StockReplenishment from './components/runs/replenishment/StockReplenishment';
import PFPReplenishment from './components/runs/replenishment/PFPReplenishment';
import StockReplenishmentDetails from './components/runs/replenishment/StockReplenishmentDetails';
import PFPReplenishmentDetails from './components/runs/replenishment/PFPReplenishmentDetails';
import WarehouseVerificationMessage from './components/common/WarehouseVerificationMessage';
import UserListing from './components/users/UserListing';
import Account from './components/users/Account';
import AddUser from './components/users/AddUser';
import EditDetails from './components/users/EditDetails';
import EditPassword from './components/users/EditPassword';
import Fulfilment from './components/fulfilment/FulfilmentLabels';
import Manifests from './components/collections/CollectionManifests';
import InboundStock from './components/fulfilment/InboundStock';
import Parcels from "./components/Issues/Parcels";
import ParcelDetails from "./components/Issues/ParcelDetails";
import Locations from "./components/Issues/Locations";
import LocationDetails from "./components/Issues/LocationDetails";
import ReturnsInProcess from "./components/returns/InProcess";
import RetailerReturnIssues from "./components/returns/RetailerReturnIssues";
import BoxLabels from "./components/returns/BoxLabels";

// 15 seconds
const CHECK_TOKEN_INTERVAL = 15000;
const unAuthenticatedRoutes = "account";

const App = () => {

  const isLoggedIn = () => {
    const user = getUser();
    return user ? true : false;
  };

  const getCurrentSelectedWarehouse =  () => {
    const warehouseInStorage = localStorage.getItem("currentlySelectedWarehouse");
    return warehouseInStorage !== null || undefined ? warehouseInStorage : '';
  }

  //#region State
  
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const [tokenExpired, setTokenExpired] = useState(false);
  const [currentlySelectedWarehouse, setCurrentlySelectedWarehouse] = useState(getCurrentSelectedWarehouse());
  const [availableWarehouses, setAvailableWarehouses] = useState([]);
  const [newUserSuccessMessage, setNewUserSuccessMessage] = useState('');
  const [showTabEditUser, setShowTabEditUser] = useState(false);

  //#endregion

  const history = useHistory();
  const location = useLocation();

  //#region Hooks

  useEffect(() => {
    if(loggedIn) {
      checkPermission('ViewWarehouseListing') && retrieveWarehouses();
      setCurrentlySelectedWarehouse(getCurrentSelectedWarehouse());
    } else {
        if(location.pathname.split("/")[1] !== unAuthenticatedRoutes) onLogout();
    }
  }, [loggedIn]);

  const checkToken = useCallback(() => {
    const loggedIn = isLoggedIn();

    if(loggedIn){
      const isTokenExpired = hasTokenExpired();
      setTokenExpired(isTokenExpired);
      if(isTokenExpired) setLoggedIn(false);
    }
  }, [loggedIn]);

  useInterval(checkToken, CHECK_TOKEN_INTERVAL);

  //#endregion

  //#region Functions

  const onLogin = (data) => {
    if (data.token) {
      localStorage.setItem('user', JSON.stringify(data));
      setTokenExpired(false);
      setLoggedIn(true);
    }
  };

  const onLogout = () => {
    setCurrentlySelectedWarehouse('');
    setAvailableWarehouses([]);
    setLoggedIn(false);
    history.push("/");
    localStorage.clear();
  };

  const retrieveWarehouses = () => {
    return GET(new URL(Endpoints.WAREHOUSE.GET.ALL))
      .catch(error => console.log(error))
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(results => {
        const data = extractData(results);
        data === null ? setAvailableWarehouses([]) : setAvailableWarehouses(data.warehouses);
      }).catch(() => setAvailableWarehouses([]));
  };

  const handleWarehouseChange = (e) => {
    const { value } = e.target;
    setCurrentlySelectedWarehouse(value);
    localStorage.setItem('currentlySelectedWarehouse', value);
  };

  //#endregion

  //#region Render

    if (loggedIn) {

      const showWarehouseSelectionWarning = hasMultipleWarehouseAccess() && currentlySelectedWarehouse === '';

      return (
        <>
        <Layout onLogout={onLogout} handleWarehouseChange={handleWarehouseChange} currentlySelectedWarehouse={currentlySelectedWarehouse} availableWarehouses={availableWarehouses}>
          <Switch>
            {showWarehouseSelectionWarning
              ? <Route path='/*' render={(props) => (<WarehouseVerificationMessage {...props} isAuthed={true} />)} />
              : <React.Fragment>
  
                {/* Dashboard*/}
                <PermissionProtectedRoute permission="ViewWMSDashboard" path={['/dashboard', '/']} render={(props) => (<QueuedRuns {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSDashboard" path='/dashboard/inprogressruns' render={(props) => (<InProgressRuns {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewProblems" path='/dashboard/issues' render={(props) => (<Issues {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
  
                {/* Issues */}
                <PermissionProtectedRoute permission="ViewProblems" path='/issues/parcels' render={(props) => (<Parcels {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewProblems" path='/issues/parcels/:id' render={(props) => (<ParcelDetails {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewProblems" path={['/issues/locations', '/issues']} render={(props) => (<Locations {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewProblems" path='/issues/locations/:id' render={(props) => (<LocationDetails {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
  
                {/* Users*/}
                <PermissionProtectedRoute permission="ViewUser" path='/company/users' render={(props) => (<UserListing  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} newUserSuccessMessage={newUserSuccessMessage} />)} />
                <PermissionProtectedRoute permission='AddUser' path='/company/users/new' render={(props) => (<AddUser  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission='EditUser' path='/company/users/account/details/:id' render={(props) => (<EditDetails showTabEditUser={showTabEditUser} isAdd={false} {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission='ViewUser' path='/company/users/account/overview/:id' render={(props) => (<Account  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission='EditUser' path='/company/users/account/password/:id' render={(props) => (<EditPassword  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
  
                {/* Orders*/}
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/order' render={(props) => (<OrderPick  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/order/pack' render={(props) => (<OrderPack  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/order/pick/:id' render={(props) => (<OrderPickDetails {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/order/pack/:id' render={(props) => (<OrderPackDetails {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
  
                {/* Bulk */}
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/bulk' render={(props) => (<BulkShelf  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} exact />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/bulk/pallet' render={(props) => (<BulkPallet  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/bulk/pack' render={(props) => (<BulkPack {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/bulk/shelf/:id' render={(props) => (<BulkShelfDetails  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/bulk/pallet/:id' render={(props) => (<BulkPalletDetails {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/bulk/pack/:id' render={(props) => (<BulkPackDetails  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
  
                {/* Replenishment*/}
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/replenishment' render={(props) => (<StockReplenishment  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/replenishment/pfp' render={(props) => (<PFPReplenishment  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/replenishment/stock/:id' render={(props) => (<StockReplenishmentDetails {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/runs/replenishment/pfp/:id' render={(props) => (<PFPReplenishmentDetails  {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
  
                { /* Fulfilment*/}
                {hasProfile("Fulfilment") &&
                  <React.Fragment>
                    <PermissionsProtectedRoute permissions={["ViewCheckinRun", "ViewLabelPrintQueue"]} path='/fulfilment/fulfilmentlabels' render={(props) => (<Fulfilment {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                    <PermissionsProtectedRoute permissions={["ViewCheckinRun", "ViewWMSInboundStock"]} path='/fulfilment/inboundstock' render={(props) => (<InboundStock {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                  </React.Fragment>}
  
                { /* Collections*/}
                <PermissionProtectedRoute permission="ViewManifests" path='/collections/collectionmanifests' render={(props) => (<Manifests {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
  
                { /* Returns*/}
                <PermissionProtectedRoute permission="ViewReturnCheckin" path='/returns/inprocess' render={(props) => (<ReturnsInProcess {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewWMSRuns" path='/returns/issues' render={(props) => (<RetailerReturnIssues {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
                <PermissionProtectedRoute permission="ViewReturnCheckin" path='/returns/boxlabels' render={(props) => (<BoxLabels {...props} isAuthed={true} currentlySelectedWarehouse={currentlySelectedWarehouse} />)} />
  
              </React.Fragment>}
  
          </Switch>
        </Layout>
        </>
    ) 
    }
      return (
        <>
        <Switch>
          <Route exact path='/' render={() => (<Login onLogin={onLogin} tokenExpired={tokenExpired} />)} />
          <Route path='/account/password/forgot' component={Forgot} exact />
          <Route path='/account/password/sent' component={Sent} exact />
          <Route path='/account/password/expired' component={Expired} exact />
          <Route path='/account/password/reset/:passwordresetid' component={Reset} exact />
          <Route path='/account/password/complete' component={Complete} exact />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
        </>
      );
    //#endregion
};

export default withRouter(App);